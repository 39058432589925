<template>
    <div class="cards">
        <h3>Cards</h3>
        <p>👉🏼 We have a default <b>card</b> structure to use</p>
        <br>
        <h5>Examples:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card'>
    YOUR CONTENT HERE
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card">
                    YOUR CONTENT HERE
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-300</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-300'>
    <img src='your_image' alt='image_alt' class='ba-image'>
    <span class='ba-title'>Title</span>
    <span class='ba-description'>Description</span>
    <button class='ba-btn ba-md ba-green'>
        Example
    </button>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-300">
                    <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                    <span class="ba-title">Title</span>
                    <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                    <button class='ba-btn ba-md ba-green'>
                        Example
                    </button>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-300</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-300'>
    <div class='ba-top'>
        <span class='ba-title'>Title</span>
        <div class='ba-box-items ba-flex ba-gp-1'>
            <button class='ba-btn ba-sm ba-blue'>Example</button>
        </div>
    </div>
    <div class='ba-content'>
        YOUR CONTENT
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-300">
                    <div class="ba-top">
                        <span class="ba-title">Title</span>
                        <div class="ba-box-items ba-flex ba-gp-1">
                            <button class="ba-btn ba-sm ba-blue">Example</button>
                        </div>
                    </div>
                    <div class="ba-content">
                        YOUR CONTENT
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-300</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-300'>
    <div class='ba-top'>
        <div class='ba-flex ba-f-column'>
            <span class='ba-title'>Title</span>
            <span class='ba-subtitle'>Lorem ipsum dolor.</span>
        </div>
        <div class='ba-box-items ba-flex ba-gp-1'>
            <button class='ba-btn ba-sm ba-blue'>Example</button>
        </div>
    </div>
    <div class='ba-content'>
        YOUR CONTENT
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-300">
                    <div class="ba-top">
                        <div class="ba-flex ba-f-column">
                            <span class="ba-title">Title</span>
                            <span class="ba-subtitle">Lorem ipsum dolor.</span>
                        </div>
                        <div class="ba-box-items ba-flex ba-gp-1">
                            <button class="ba-btn ba-sm ba-blue">Example</button>
                        </div>
                    </div>
                    <div class="ba-content">
                        YOUR CONTENT
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-200</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-200'>
    Example
    <hr class='ba-hr-row'>
    Example
    <hr class='ba-hr-column'>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-200">
                    Example
                    <hr class="ba-hr-row">
                    Example
                    <hr class="ba-hr-column">
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-statistics ba-300</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-statistics ba-300'>
    <div class='ba-top'>
        <span class='ba-icon'>
            <builderall-icon code='star' />
        </span>
        <span class='ba-title'>Title</span>
    </div>
    <div class='ba-content ba-jc-center'>
        <span class='ba-data'>00.00</span>
    </div>
    <div class='ba-bottom'>
        <button class='ba-btn ba-sm ba-blue'>
            Example
        </button>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-statistics ba-300">
                    <div class="ba-top">
                        <span class="ba-icon">
                            <builderall-icon code="star" />
                        </span>
                        <span class="ba-title">Title</span>
                    </div>
                    <div class="ba-content ba-jc-center">
                        <span class="ba-data">00.000</span>
                    </div>
                    <div class="ba-bottom">
                        <button class='ba-btn ba-sm ba-blue'>
                            Example
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-statistics ba-300 > ba-green</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-statistics ba-300'>
    <div class='ba-top'>
        <span class='ba-icon'>
            <builderall-icon code='star' />
        </span>
        <span class='ba-title'>Title</span>
    </div>
    <div class='ba-content ba-green ba-jc-center'>
        <span class='ba-data'>- 00%</span>
        <span class='ba-icon'>
            <builderall-icon code='star' />
        </span>
    </div>
    <div class='ba-bottom'>
        <button class='ba-btn ba-sm ba-blue'>
            Example
        </button>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-statistics ba-300">
                    <div class="ba-top">
                        <span class="ba-icon">
                            <builderall-icon code='star' />
                        </span>
                        <span class="ba-title">Title</span>
                    </div>
                    <div class="ba-content ba-green ba-jc-center">
                        <span class="ba-data">+ 00%</span>
                        <span class="ba-icon">
                            <builderall-icon code='6a1a' color="#01d4b9" />
                        </span>
                    </div>
                    <div class="ba-bottom">
                        <button class='ba-btn ba-sm ba-blue'>
                            Example
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-statistics ba-300 > ba-red</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-statistics ba-300'>
    <div class='ba-top'>
        <span class='ba-icon'>
            <builderall-icon code='star' />
        </span>
        <span class='ba-title'>Title</span>
    </div>
    <div class='ba-content ba-red ba-jc-center'>
        <span class='ba-data'>- 00%</span>
        <span class='ba-icon'>
            YOUR_SVG
        </span>
    </div>
    <div class='ba-bottom'>
        <button class='ba-btn ba-sm ba-blue'>
            Example
        </button>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-statistics ba-300">
                    <div class="ba-top">
                        <span class="ba-icon">
                            <builderall-icon code='star' />
                        </span>
                        <span class="ba-title">Title</span>
                    </div>
                    <div class="ba-content ba-red ba-jc-center">
                        <span class="ba-data">- 00%</span>
                        <span class="ba-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 13.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h4.793L2.146 2.854a.5.5 0 1 1 .708-.708L13 12.293V7.5a.5.5 0 0 1 1 0v6z"/>
                            </svg>
                        </span>
                    </div>
                    <div class="ba-bottom">
                        <button class='ba-btn ba-sm ba-blue'>
                            Example
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-statistics ba-300 > ba-green</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-statistics ba-300'>
    <div class='ba-top'>
        <span class='ba-icon'>
            <builderall-icon code='star' />
        </span>
        <span class='ba-title'>Title</span>
    </div>
    <div class='ba-content ba-jc-fs'>
        <span class='ba-data'>123</span>
    </div>
    <div class='ba-bottom'>
        <span class='ba-text'>
            Example: 
            <b class='ba-green'>00</b>
        </span>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-statistics ba-300">
                    <div class="ba-top">
                        <span class="ba-icon">
                            <builderall-icon code='star' />
                        </span>
                        <span class="ba-title">Title</span>
                    </div>
                    <div class="ba-content ba-jc-fs">
                        <span class="ba-data">132</span>
                    </div>
                    <div class="ba-bottom">
                        <span class="ba-text">Example: <b class="ba-green">00</b></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-card ba-statistics ba-300 > ba-red</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-statistics ba-300'>
    <div class='ba-top'>
        <span class='ba-icon'>
            <builderall-icon code='star' />
        </span>
        <span class='ba-title'>Title</span>
    </div>
    <div class='ba-content ba-jc-center'>
        <span class='ba-data'>123</span>
    </div>
    <div class='ba-bottom'>
        <span class='ba-text'>
            Example: 
            <b class='ba-red'>00</b>
        </span>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card ba-statistics ba-300">
                    <div class="ba-top">
                        <span class="ba-icon">
                            <builderall-icon code='star' />
                        </span>
                        <span class="ba-title">Title</span>
                    </div>
                    <div class="ba-content ba-jc-center">
                        <span class="ba-data">123</span>
                    </div>
                    <div class="ba-bottom">
                        <span class="ba-text">Example: <b class="ba-red">00</b></span>
                    </div>
                </div>
            </div>
        </div>
        <hr><br>
        <h5>Structure to use in tools:</h5>
        <div class="ba-pd-1">
            <div>
                <p>Type: <b>ba-card ba-card-grid</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card ba-card-grid'>
    <div class='ba-top'>
        <span class='ba-title'>Title</span>
        <div class='ba-box-items ba-flex ba-gp-1'>
            <div class='ba-box-tabs'>
                <a href='#' class='ba-tab ba-is-active'>7 days</a>
                <a href='#' class='ba-tab'>30 days</a>
                <a href='#' class='ba-tab'>All Time</a>
            </div>
        </div>
    </div>
    <div class='ba-content'>
        <div class='ba-card ba-statistics'>
            <div class='ba-top'>
                <span class='ba-icon'>
                    <builderall-icon code='YOUR_ICON' />
                </span>
                <span class='ba-title'>Title</span>
            </div>
            <div class='ba-content ba-jc-fs'>
                <span class='ba-data'>123</span>
            </div>
            <div class='ba-bottom'>
                <span class='ba-text'>Example:</span>
                <span class='ba-number ba-green'>
                    <b class='ba-number-data'>100%</b>
                    <span class='ba-number-icon'>
                        <svg width='10' height='10' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M9.07143 3.92857L2.73214 10.2857C2.58929 10.4286 2.41952 10.5 2.22286 10.5C2.02619 10.5 1.85667 10.4286 1.71429 10.2857C1.57143 10.1429 1.5 9.97309 1.5 9.77643C1.5 9.57976 1.57143 9.41024 1.71429 9.26786L8.07143 2.92857H2.64286C2.44048 2.92857 2.27071 2.86 2.13357 2.72286C1.99643 2.58572 1.9281 2.41619 1.92857 2.21429C1.92857 2.01191 1.99714 1.84215 2.13429 1.705C2.27143 1.56786 2.44095 1.49953 2.64286 1.5H9.78571C9.98809 1.5 10.1579 1.56857 10.295 1.70572C10.4321 1.84286 10.5005 2.01238 10.5 2.21429V9.35714C10.5 9.55952 10.4314 9.72929 10.2943 9.86643C10.1571 10.0036 9.98762 10.0719 9.78571 10.0714C9.58333 10.0714 9.41357 10.0029 9.27643 9.86571C9.13928 9.72857 9.07095 9.55905 9.07143 9.35714V3.92857Z' fill='#18CA9D'/>
                        </svg>
                    </span>
                </span>
            </div>
        </div>
        <hr class='ba-hr-column'>
        <div class='ba-card ba-statistics'>
            <div class='ba-top'>
                <span class='ba-icon'>
                    <builderall-icon code='YOUR_ICON' />
                </span>
                <span class='ba-title'>Title</span>
            </div>
            <div class='ba-content ba-jc-center'>
                <span class='ba-data'>123</span>
            </div>
            <div class='ba-bottom'>
                <span class='ba-text'>Example:</span>
                <span class='ba-number ba-red'>
                    <b class='ba-number-data'>100%</b>
                    <span class='ba-number-icon'>
                        <svg width='10' height='10' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M8.07143 9.07143L1.71429 2.73214C1.57143 2.58928 1.5 2.41952 1.5 2.22286C1.5 2.02619 1.57143 1.85667 1.71429 1.71429C1.85714 1.57143 2.02691 1.5 2.22357 1.5C2.42024 1.5 2.58976 1.57143 2.73214 1.71429L9.07143 8.07143L9.07143 2.64286C9.07143 2.44048 9.14 2.27071 9.27714 2.13357C9.41428 1.99643 9.58381 1.92809 9.78571 1.92857C9.98809 1.92857 10.1579 1.99714 10.295 2.13429C10.4321 2.27143 10.5005 2.44095 10.5 2.64286L10.5 9.78571C10.5 9.98809 10.4314 10.1579 10.2943 10.295C10.1571 10.4321 9.98762 10.5005 9.78571 10.5L2.64286 10.5C2.44048 10.5 2.27071 10.4314 2.13357 10.2943C1.99643 10.1571 1.92809 9.98762 1.92857 9.78571C1.92857 9.58333 1.99714 9.41357 2.13429 9.27643C2.27143 9.13928 2.44095 9.07095 2.64286 9.07143L8.07143 9.07143Z' fill='#FF3636'/>
                        </svg>
                    </span>
                </span>
            </div>
        </div>
        <hr class='ba-hr-column'>
        <div class='ba-card ba-statistics'>
            <div class='ba-top'>
                <span class='ba-icon'>
                    <builderall-icon code='YOUR_ICON' />
                </span>
                <span class='ba-title'>Title</span>
            </div>
            <div class='ba-content ba-jc-fs'>
                <span class='ba-data'>123</span>
            </div>
            <div class='ba-bottom'>
                <span class='ba-text'>Example:</span>
                <span class='ba-number ba-yellow'>
                    <b class='ba-number-data'>100%</b>
                    <span class='ba-number-icon'>
                        <svg width='10' height='10' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M9.07143 3.92857L2.73214 10.2857C2.58929 10.4286 2.41952 10.5 2.22286 10.5C2.02619 10.5 1.85667 10.4286 1.71429 10.2857C1.57143 10.1429 1.5 9.97309 1.5 9.77643C1.5 9.57976 1.57143 9.41024 1.71429 9.26786L8.07143 2.92857H2.64286C2.44048 2.92857 2.27071 2.86 2.13357 2.72286C1.99643 2.58572 1.9281 2.41619 1.92857 2.21429C1.92857 2.01191 1.99714 1.84215 2.13429 1.705C2.27143 1.56786 2.44095 1.49953 2.64286 1.5H9.78571C9.98809 1.5 10.1579 1.56857 10.295 1.70572C10.4321 1.84286 10.5005 2.01238 10.5 2.21429V9.35714C10.5 9.55952 10.4314 9.72929 10.2943 9.86643C10.1571 10.0036 9.98762 10.0719 9.78571 10.0714C9.58333 10.0714 9.41357 10.0029 9.27643 9.86571C9.13928 9.72857 9.07095 9.55905 9.07143 9.35714V3.92857Z' fill='#EE9500'/>
                        </svg>
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
                  "
                  height="auto"
                  width="1000px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class='ba-card ba-card-grid'>
                    <div class='ba-top'>
                        <span class='ba-title'>Title</span>
                        <div class='ba-box-items ba-flex ba-gp-1'>
                            <div class='ba-box-tabs'>
                                <a href='#' class='ba-tab ba-is-active'>7 days</a>
                                <a href='#' class='ba-tab'>30 days</a>
                                <a href='#' class='ba-tab'>All Time</a>
                            </div>
                        </div>
                    </div>
                    <div class='ba-content'>
                        <div class='ba-card ba-statistics'>
                            <div class='ba-top'>
                                <span class='ba-icon'>
                                    <builderall-icon code="star" />
                                </span>
                                <span class='ba-title'>Title</span>
                            </div>
                            <div class='ba-content ba-jc-fs'>
                                <span class='ba-data'>123</span>
                            </div>
                            <div class='ba-bottom'>
                                <span class='ba-text'>Example:</span>
                                <span class='ba-number ba-green'>
                                    <b class="ba-number-data">100%</b>
                                    <span class="ba-number-icon">
                                        <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.07143 3.92857L2.73214 10.2857C2.58929 10.4286 2.41952 10.5 2.22286 10.5C2.02619 10.5 1.85667 10.4286 1.71429 10.2857C1.57143 10.1429 1.5 9.97309 1.5 9.77643C1.5 9.57976 1.57143 9.41024 1.71429 9.26786L8.07143 2.92857H2.64286C2.44048 2.92857 2.27071 2.86 2.13357 2.72286C1.99643 2.58572 1.9281 2.41619 1.92857 2.21429C1.92857 2.01191 1.99714 1.84215 2.13429 1.705C2.27143 1.56786 2.44095 1.49953 2.64286 1.5H9.78571C9.98809 1.5 10.1579 1.56857 10.295 1.70572C10.4321 1.84286 10.5005 2.01238 10.5 2.21429V9.35714C10.5 9.55952 10.4314 9.72929 10.2943 9.86643C10.1571 10.0036 9.98762 10.0719 9.78571 10.0714C9.58333 10.0714 9.41357 10.0029 9.27643 9.86571C9.13928 9.72857 9.07095 9.55905 9.07143 9.35714V3.92857Z" fill="#18CA9D"/>
                                        </svg>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <hr class='ba-hr-column'>
                        <div class='ba-card ba-statistics'>
                            <div class='ba-top'>
                                <span class='ba-icon'>
                                    <builderall-icon code="star" />
                                </span>
                                <span class='ba-title'>Title</span>
                            </div>
                            <div class='ba-content ba-jc-center'>
                                <span class='ba-data'>123</span>
                            </div>
                            <div class='ba-bottom'>
                                <span class='ba-text'>Example:</span>
                                <span class='ba-number ba-red'>
                                    <b class="ba-number-data">100%</b>
                                    <span class="ba-number-icon">
                                        <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.07143 9.07143L1.71429 2.73214C1.57143 2.58928 1.5 2.41952 1.5 2.22286C1.5 2.02619 1.57143 1.85667 1.71429 1.71429C1.85714 1.57143 2.02691 1.5 2.22357 1.5C2.42024 1.5 2.58976 1.57143 2.73214 1.71429L9.07143 8.07143L9.07143 2.64286C9.07143 2.44048 9.14 2.27071 9.27714 2.13357C9.41428 1.99643 9.58381 1.92809 9.78571 1.92857C9.98809 1.92857 10.1579 1.99714 10.295 2.13429C10.4321 2.27143 10.5005 2.44095 10.5 2.64286L10.5 9.78571C10.5 9.98809 10.4314 10.1579 10.2943 10.295C10.1571 10.4321 9.98762 10.5005 9.78571 10.5L2.64286 10.5C2.44048 10.5 2.27071 10.4314 2.13357 10.2943C1.99643 10.1571 1.92809 9.98762 1.92857 9.78571C1.92857 9.58333 1.99714 9.41357 2.13429 9.27643C2.27143 9.13928 2.44095 9.07095 2.64286 9.07143L8.07143 9.07143Z" fill="#FF3636"/>
                                        </svg>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <hr class='ba-hr-column'>
                        <div class='ba-card ba-statistics'>
                            <div class='ba-top'>
                                <span class='ba-icon'>
                                    <builderall-icon code="star" />
                                </span>
                                <span class='ba-title'>Title</span>
                            </div>
                            <div class='ba-content ba-jc-fs'>
                                <span class='ba-data'>123</span>
                            </div>
                            <div class='ba-bottom'>
                                <span class='ba-text'>Example:</span>
                                <span class='ba-number ba-yellow'>
                                    <b class="ba-number-data">100%</b>
                                    <span class="ba-number-icon">
                                        <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.07143 3.92857L2.73214 10.2857C2.58929 10.4286 2.41952 10.5 2.22286 10.5C2.02619 10.5 1.85667 10.4286 1.71429 10.2857C1.57143 10.1429 1.5 9.97309 1.5 9.77643C1.5 9.57976 1.57143 9.41024 1.71429 9.26786L8.07143 2.92857H2.64286C2.44048 2.92857 2.27071 2.86 2.13357 2.72286C1.99643 2.58572 1.9281 2.41619 1.92857 2.21429C1.92857 2.01191 1.99714 1.84215 2.13429 1.705C2.27143 1.56786 2.44095 1.49953 2.64286 1.5H9.78571C9.98809 1.5 10.1579 1.56857 10.295 1.70572C10.4321 1.84286 10.5005 2.01238 10.5 2.21429V9.35714C10.5 9.55952 10.4314 9.72929 10.2943 9.86643C10.1571 10.0036 9.98762 10.0719 9.78571 10.0714C9.58333 10.0714 9.41357 10.0029 9.27643 9.86571C9.13928 9.72857 9.07095 9.55905 9.07143 9.35714V3.92857Z" fill="#EE9500"/>
                                        </svg>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b>ba-card ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-300">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <span class="ba-title">Title</span>
                            <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                            <button class='ba-btn ba-md ba-green'>
                                Example
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-blue ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-blue ba-300">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <span class="ba-title">Title</span>
                            <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                            <button class='ba-btn ba-md ba-green'>
                                Example
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-no-shadow ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-no-shadow ba-300">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <span class="ba-title">Title</span>
                            <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                            <button class='ba-btn ba-md ba-green'>
                                Example
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-blue ba-no-shadow ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-blue ba-no-shadow ba-300">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <span class="ba-title">Title</span>
                            <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                            <button class='ba-btn ba-md ba-green'>
                                Example
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-no-padding ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-no-padding ba-300">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <span class="ba-title">Title</span>
                            <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                            <button class='ba-btn ba-md ba-green'>
                                Example
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-row</b>
                    </td>
                    <td>
                        <div class="ba-card ba-row">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <div class="ba-content">
                                <span class="ba-title">Title</span>
                                <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                                <button class='ba-btn ba-md ba-green'>
                                    Example
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-row ba-blue</b>
                    </td>
                    <td>
                        <div class="ba-card ba-row ba-blue">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <div class="ba-content">
                                <span class="ba-title">Title</span>
                                <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                                <button class='ba-btn ba-md ba-green'>
                                    Example
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-row ba-no-shadow</b>
                    </td>
                    <td>
                        <div class="ba-card ba-row ba-no-shadow">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <div class="ba-content">
                                <span class="ba-title">Title</span>
                                <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                                <button class='ba-btn ba-md ba-green'>
                                    Example
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-row ba-blue ba-no-shadow</b>
                    </td>
                    <td>
                        <div class="ba-card ba-row ba-blue ba-no-shadow">
                            <img src="https://t4.ftcdn.net/jpg/01/28/98/53/360_F_128985367_mNdX0b56w6OcgiyUDnZwukpK1bkpfWwI.jpg" alt="image_alt" class="ba-image">
                            <div class="ba-content">
                                <span class="ba-title">Title</span>
                                <span class="ba-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit doloremque tempora aut doloribus reiciendis, quam, beatae sapiente enim pariatur odit blanditiis non ipsum ab id dolorem laborum animi, sunt temporibus!</span>
                                <button class='ba-btn ba-md ba-green'>
                                    Example
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card</b>
                    </td>
                    <td>
                        <div class="ba-card">
                            <div class="ba-top">
                                <span class="ba-title">Title</span>
                                <div class="ba-box-items ba-flex ba-gp-1">
                                    <button class="ba-btn ba-sm ba-blue">Example</button>
                                </div>
                            </div>
                            <div class="ba-content">
                                YOUR CONTENT
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-statistics ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-statistics ba-300">
                            <div class="ba-top">
                                <span class="ba-icon">
                                    <builderall-icon code='star' />
                                </span>
                                <span class="ba-title">Title</span>
                            </div>
                            <div class="ba-content ba-jc-center">
                                <span class="ba-data">00.000</span>
                            </div>
                            <div class="ba-bottom">
                                <button class='ba-btn ba-sm ba-blue'>
                                    Example
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-statistics ba-300 > ba-green</b>
                    </td>
                    <td>
                        <div class="ba-card ba-statistics ba-300">
                            <div class="ba-top">
                                <span class="ba-icon">
                                    <builderall-icon code='star' />
                                </span>
                                <span class="ba-title">Title</span>
                            </div>
                            <div class="ba-content ba-green ba-jc-center">
                                <span class="ba-data">00.000</span>
                            </div>
                            <div class="ba-bottom">
                                <button class='ba-btn ba-sm ba-blue'>
                                    Example
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-statistics ba-300 > ba-red</b>
                    </td>
                    <td>
                        <div class="ba-card ba-statistics ba-300">
                            <div class="ba-top">
                                <span class="ba-icon">
                                    <builderall-icon code='star' />
                                </span>
                                <span class="ba-title">Title</span>
                            </div>
                            <div class="ba-content ba-red ba-jc-center">
                                <span class="ba-data">00.000</span>
                            </div>
                            <div class="ba-bottom">
                                <button class='ba-btn ba-sm ba-blue'>
                                    Example
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-statistics ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-statistics ba-300">
                            <div class="ba-top">
                                <span class="ba-icon">
                                    <builderall-icon code='star' />
                                </span>
                                <span class="ba-title">Title</span>
                            </div>
                            <div class="ba-content ba-jc-center">
                                <span class="ba-data">123</span>
                            </div>
                            <div class="ba-bottom">
                                <span class="ba-text">Example: <b>00</b></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-statistics ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-statistics ba-300">
                            <div class="ba-top">
                                <span class="ba-icon">
                                    <builderall-icon code='star' />
                                </span>
                                <span class="ba-title">Title</span>
                            </div>
                            <div class="ba-content ba-jc-fs">
                                <span class="ba-data">123</span>
                            </div>
                            <div class="ba-bottom">
                                <span class="ba-text">Example: <b class="ba-green">00</b></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-statistics ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-statistics ba-300">
                            <div class="ba-top">
                                <span class="ba-icon">
                                    <builderall-icon code='star' />
                                </span>
                                <span class="ba-title">Title</span>
                            </div>
                            <div class="ba-content ba-jc-fe">
                                <span class="ba-data">123</span>
                            </div>
                            <div class="ba-bottom">
                                <span class="ba-text">Example: <b class="ba-red">00</b></span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Sizes:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b>ba-card ba-100</b>
                    </td>
                    <td>
                        <div class="ba-card ba-100">
                            YOUR CONTENT
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-200</b>
                    </td>
                    <td>
                        <div class="ba-card ba-200">
                            YOUR CONTENT
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-300</b>
                    </td>
                    <td>
                        <div class="ba-card ba-300">
                            YOUR CONTENT
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-400</b>
                    </td>
                    <td>
                        <div class="ba-card ba-400">
                            YOUR CONTENT
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-card ba-500</b>
                    </td>
                    <td>
                        <div class="ba-card ba-500">
                            YOUR CONTENT
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>