<template>
    <div class="footer">
        <h3>Footer</h3>
        <p>👉🏼 The footer is provided through a web component defined in the Wrapper script. You just need to wrap it inside a div class "<b>ba-footer</b>"</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-f-column ba-ai-center">
            <div>
                <p>Type: <b>ba-footer</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-footer'>
    <builderall-footer></builderall-footer>
</div>
                  "
                  height="auto"
                />
            </div>
            <img src="../../public/footer.png" alt="Footer image example">
        </div>
    </div>
</template>