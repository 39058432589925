<template>
    <div class="switchs">
        <h3>Switchs</h3>
        <p>👉🏼 We have <b>Switchs</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-switch ba-md</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<label class='ba-switch ba-md'>
    <input type='checkbox' class='ba-checkbox' checked>
    <span class='ba-slider'></span>
</label>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <label class="ba-switch ba-md">
                    <input type="checkbox" class="ba-checkbox" checked>
                    <span class="ba-slider"></span>
                </label>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Size</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-sm</td>
                    <td>
                        <label class="ba-switch ba-sm">
                            <input type="checkbox" class="ba-checkbox" checked>
                            <span class="ba-slider"></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>
                        <label class="ba-switch ba-md">
                            <input type="checkbox" class="ba-checkbox" checked>
                            <span class="ba-slider"></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>
                        <label class="ba-switch ba-lg">
                            <input type="checkbox" class="ba-checkbox" checked>
                            <span class="ba-slider"></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>ba-xl</td>
                    <td>
                        <label class="ba-switch ba-xl">
                            <input type="checkbox" class="ba-checkbox" checked>
                            <span class="ba-slider"></span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>