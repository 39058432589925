<template>
    <div class="fonts">
        <h3>Fonts</h3>
        <p>👉🏼 We have <b>Montserrat</b> and <b>Mulish</b> fonts in project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>normal</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<h1>A</h1>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <h1>A</h1>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Tag</th>
                    <th>Size</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>H1</td>
                    <td>3.5rem - 56px</td>
                    <td>
                        <h1>A</h1>
                    </td>
                </tr>
                <tr>
                    <td>H2</td>
                    <td>3rem - 48px</td>
                    <td>
                        <h2>A</h2>
                    </td>
                </tr>
                <tr>
                    <td>H3</td>
                    <td>2.25rem - 36px</td>
                    <td>
                        <h3>A</h3>
                    </td>
                </tr>
                <tr>
                    <td>H4</td>
                    <td>2rem - 32px</td>
                    <td>
                        <h4>A</h4>
                    </td>
                </tr>
                <tr>
                    <td>H5</td>
                    <td>1.5rem - 24px</td>
                    <td>
                        <h5>A</h5>
                    </td>
                </tr>
                <tr>
                    <td>H6</td>
                    <td>1.1rem - 17.6px</td>
                    <td>
                        <h6>A</h6>
                    </td>
                </tr>
                <tr>
                    <td>P</td>
                    <td>1rem - 16px</td>
                    <td>
                        <p>Lorem ipsum dolor sit</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Text align:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>ba-text-center</b></td>
                    <td>text-align: center;</td>
                    <td>
                        <p class="ba-text-center">Builderall</p>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-text-left</b></td>
                    <td>text-align: left;</td>
                    <td>
                        <p class="ba-text-left">Builderall</p>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-text-right</b></td>
                    <td>text-align: right;</td>
                    <td>
                        <p class="ba-text-right">Builderall</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Text transform:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>ba-text-uppercase</b></td>
                    <td>text-transform: uppercase;</td>
                    <td>
                        <p class="ba-text-uppercase">Builderall Example</p>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-text-lowercase</b></td>
                    <td>text-transform: lowercase;</td>
                    <td>
                        <p class="ba-text-lowercase">Builderall Example</p>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-text-capitalize</b></td>
                    <td>text-transform: capitalize;</td>
                    <td>
                        <p class="ba-text-capitalize">Builderall example</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Text decoration:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>ba-text-underline</b></td>
                    <td>text-decoration: underine;</td>
                    <td>
                        <p class="ba-text-underline">Builderall Example</p>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-text-line-through</b></td>
                    <td>text-decoration: line-through;</td>
                    <td>
                        <p class="ba-text-line-through">Builderall Example</p>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-text-overline</b></td>
                    <td>text-decoration: overline;</td>
                    <td>
                        <p class="ba-text-overline">Builderall example</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5><b>Montserrat</b> Font Family:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Font Family</th>
                    <th>Font Weight</th>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Montserrat</td>
                    <td>100</td>
                    <td>ba-montserrat-100</td>
                    <td>
                        <p class="ba-montserrat-100">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>200</td>
                    <td>ba-montserrat-200</td>
                    <td>
                        <p class="ba-montserrat-200">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>300</td>
                    <td>ba-montserrat-300</td>
                    <td>
                        <p class="ba-montserrat-300">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>400</td>
                    <td>ba-montserrat-400</td>
                    <td>
                        <p class="ba-montserrat-400">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>500</td>
                    <td>ba-montserrat-500</td>
                    <td>
                        <p class="ba-montserrat-500">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>600</td>
                    <td>ba-montserrat-600</td>
                    <td>
                        <p class="ba-montserrat-600">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>700</td>
                    <td>ba-montserrat-700</td>
                    <td>
                        <p class="ba-montserrat-700">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>800</td>
                    <td>ba-montserrat-800</td>
                    <td>
                        <p class="ba-montserrat-800">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>900</td>
                    <td>ba-montserrat-900</td>
                    <td>
                        <p class="ba-montserrat-900">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Montserrat</td>
                    <td>bold</td>
                    <td>ba-montserrat-bold</td>
                    <td>
                        <p class="ba-montserrat-bold">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5><b>Mulish</b> Font Family:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Font Family</th>
                    <th>Font Weight</th>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Mulish</td>
                    <td>100</td>
                    <td>ba-mulish-100</td>
                    <td>
                        <p class="ba-mulish-100">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>200</td>
                    <td>ba-mulish-200</td>
                    <td>
                        <p class="ba-mulish-200">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>300</td>
                    <td>ba-mulish-300</td>
                    <td>
                        <p class="ba-mulish-300">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>400</td>
                    <td>ba-mulish-400</td>
                    <td>
                        <p class="ba-mulish-400">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>500</td>
                    <td>ba-mulish-500</td>
                    <td>
                        <p class="ba-mulish-500">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>600</td>
                    <td>ba-mulish-600</td>
                    <td>
                        <p class="ba-mulish-600">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>700</td>
                    <td>ba-mulish-700</td>
                    <td>
                        <p class="ba-mulish-700">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>800</td>
                    <td>ba-mulish-800</td>
                    <td>
                        <p class="ba-mulish-800">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>900</td>
                    <td>ba-mulish-900</td>
                    <td>
                        <p class="ba-mulish-900">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
                <tr>
                    <td>Mulish</td>
                    <td>bold</td>
                    <td>ba-mulish-bold</td>
                    <td>
                        <p class="ba-mulish-bold">Lorem ipsum dolor sit</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>