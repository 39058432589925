<template>
    <div class="button">
        <h3>Button Component</h3>
        <p>👉🏼 We have multiple <b>buttons</b></p>
        <br>
        <h5>Examples:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-btn ba-md ba-green</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<button class='ba-btn ba-md ba-green'>
    Submit
</button>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <button class="ba-btn ba-md ba-green">
                    Submit
                </button>
            </div>
        </div>
        <br>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-btn ba-md ba-blue</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<button class='ba-btn ba-md ba-blue'>
    <builderall-icon code='YOUR_SVG' />
    Submit
    <builderall-icon code='YOUR_SVG' />
</button>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <button class="ba-btn ba-md ba-blue">
                    <builderall-icon code='star' />
                    Submit
                    <builderall-icon code='star' />
                </button>
            </div>
        </div>
        <br><br>
        <h5>Buttons to use in your table:</h5>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-btn-normal</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<button class='ba-btn ba-btn-normal ba-sm ba-rounded'>
    <builderall-icon code='edit' />
</button>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <button class="ba-btn ba-btn-normal ba-sm ba-rounded">
                    <builderall-icon code='edit' />
                </button>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-btn-trash</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<button class='ba-btn ba-btn-trash ba-sm ba-rounded'>
    <builderall-icon code='trash' />
</button>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <button class="ba-btn ba-btn-trash ba-sm ba-rounded">
                    <builderall-icon code='trash' />
                </button>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-btn-success</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<button class='ba-btn ba-btn-success ba-sm ba-rounded'>
    <builderall-icon code='star' />
</button>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <button class="ba-btn ba-btn-success ba-sm ba-rounded">
                    <builderall-icon code='star' />
                </button>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-btn-close</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<button class='ba-btn ba-btn-close ba-sm ba-rounded'>
    <builderall-icon code='64f7' />
</button>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <button class="ba-btn ba-btn-close ba-sm ba-rounded">
                    <builderall-icon code='64f7' />
                </button>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-btn-add</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<button class='ba-btn ba-btn-add ba-sm ba-rounded'>
    <builderall-icon code='2780'  />
</button>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <button class="ba-btn ba-btn-add ba-sm ba-rounded">
                    <builderall-icon code='2780' />
                </button>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Size</th>
                    <th>Color</th>
                    <th>Status</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-blue</td>
                    <td>ba-btn-normal ba-rounded</td>
                    <td>
                        <button class="ba-btn ba-sm ba-btn-normal ba-rounded">
                            <builderall-icon code='edit' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-blue</td>
                    <td>ba-btn-normal</td>
                    <td>
                        <button class="ba-btn ba-sm ba-btn-normal">
                            <builderall-icon code='edit' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-blue</td>
                    <td>ba-btn-trash ba-rounded</td>
                    <td>
                        <button class="ba-btn ba-sm ba-btn-trash ba-rounded">
                            <builderall-icon code='trash' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-blue</td>
                    <td>ba-btn-trash</td>
                    <td>
                        <button class="ba-btn ba-sm ba-btn-trash">
                            <builderall-icon code='trash' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-blue</td>
                    <td>ba-btn-close ba-rounded</td>
                    <td>
                        <button class="ba-btn ba-sm ba-btn-close ba-rounded">
                            <builderall-icon code='64f7' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-blue</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-sm ba-blue">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-blue</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-sm ba-blue">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-blue</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-blue">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-blue</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-lg ba-blue">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-xl</td>
                    <td>ba-blue</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-xl ba-blue">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-blue</td>
                    <td>disabled</td>
                    <td>
                        <button disabled class="ba-btn ba-md ba-blue">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-blue</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-blue">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-cursor" viewBox="0 0 16 16">
                                <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z"/>
                            </svg>
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-blue</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-blue">
                            Submit
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-cursor" viewBox="0 0 16 16">
                                <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-blue</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-blue">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-cursor" viewBox="0 0 16 16">
                                <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z"/>
                            </svg>
                            Submit
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-cursor" viewBox="0 0 16 16">
                                <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>default</td>
                    <td>ba-rounded</td>
                    <td>
                        <button class="ba-btn ba-sm ba-rounded">
                            <builderall-icon code='star' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>default</td>
                    <td>ba-rounded</td>
                    <td>
                        <button class="ba-btn ba-md ba-rounded">
                            <builderall-icon code='star' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>default</td>
                    <td>ba-rounded</td>
                    <td>
                        <button class="ba-btn ba-lg ba-rounded">
                            <builderall-icon code='star' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-xl</td>
                    <td>default</td>
                    <td>ba-rounded</td>
                    <td>
                        <button class="ba-btn ba-xl ba-rounded">
                            <builderall-icon code='star' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>default</td>
                    <td>ba-rounded disabled</td>
                    <td>
                        <button disabled class="ba-btn ba-sm ba-rounded">
                            <builderall-icon code='star' />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-green</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-sm ba-green">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-green</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-green">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-green</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-lg ba-green">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-xl</td>
                    <td>ba-green</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-xl ba-green">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-green</td>
                    <td>disabled</td>
                    <td>
                        <button disabled class="ba-btn ba-md ba-green">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-orange</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-sm ba-orange">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-orange</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-orange">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-orange</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-lg ba-orange">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-xl</td>
                    <td>ba-orange</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-xl ba-orange">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-orange</td>
                    <td>disabled</td>
                    <td>
                        <button disabled class="ba-btn ba-md ba-orange">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-red</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-sm ba-red">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-red</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-red">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-red</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-lg ba-red">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-xl</td>
                    <td>ba-red</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-xl ba-red">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-red</td>
                    <td>disabled</td>
                    <td>
                        <button disabled class="ba-btn ba-md ba-red">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-light</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-sm ba-light">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-light</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-light">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-light</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-lg ba-light">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-xl</td>
                    <td>ba-light</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-xl ba-light">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-light</td>
                    <td>disabled</td>
                    <td>
                        <button disabled class="ba-btn ba-md ba-light">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-dark</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-sm ba-dark">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-dark</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-md ba-dark">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-dark</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-lg ba-dark">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-xl</td>
                    <td>ba-dark</td>
                    <td></td>
                    <td>
                        <button class="ba-btn ba-xl ba-dark">
                            Submit
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-dark</td>
                    <td>disabled</td>
                    <td>
                        <button disabled class="ba-btn ba-md ba-dark">
                            Submit
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>