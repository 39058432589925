<template>
    <div class="steps">
        <h3>Steps</h3>
        <p>👉🏼 We have <b>Steps</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-step</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-step'>
    <div class='ba-step-element ba-step-active'>
        <i class='ba-step-icon'>
            <builderall-icon code='clickmap'></builderall-icon>
        </i>
        <span class='ba-step-title'>Title</span>
    </div>
    <div class='ba-step-element ba-step-active'>
        <i class='ba-step-icon'>
            <builderall-icon code='clickmap'></builderall-icon>
        </i>
        <span class='ba-step-title'>Title</span>
    </div>
    <div class='ba-step-element'>
        <i class='ba-step-icon'>
            <builderall-icon code='clickmap'></builderall-icon>
        </i>
        <span class='ba-step-title'>Title</span>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-step">
                    <div class="ba-step-element ba-step-active">
                        <i class="ba-step-icon">
                            <builderall-icon code='clickmap'></builderall-icon>
                        </i>
                        <span class="ba-step-title">Title</span>
                    </div>
                    <div class="ba-step-element ba-step-active">
                        <i class="ba-step-icon">
                            <builderall-icon code='clickmap'></builderall-icon>
                        </i>
                        <span class="ba-step-title">Title</span>
                    </div>
                    <div class="ba-step-element">
                        <i class="ba-step-icon">
                            <builderall-icon code='clickmap'></builderall-icon>
                        </i>
                        <span class="ba-step-title">Title</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>