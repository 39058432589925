<template>
    <div class="getting-started">
        <h3>Getting Started</h3>
        <p>👋🏼 Hello! <b>Builderall.css</b> is a frontend lib (CSS) and <b>Builderall.appshell</b> (Wrapper), used by Builderall company</p>
        <br>
        <h5><b>Step 1:</b> Install dependencies</h5>
        <br>
        <p>CDN: Install Builderall.css (Lib)</p>
        <hr><br>
        <b>.com</b>
        <HighCode 
          lang="html"
          codeValue="<link rel='stylesheet' href='https://css.builderall.com/builderall.css'>"
          height="auto"
          width="70%"
        /><br>
        <b>.info</b>
        <HighCode 
          lang="html"
          codeValue="<link rel='stylesheet' href='https://css.kub.app.builderall.info/builderall.css'>"
          height="auto"
          width="70%"
        /><br>
        <b>.io</b>
        <HighCode 
          lang="html"
          codeValue="<link rel='stylesheet' href='https://css.kub.app.builderall.io/builderall.css'>"
          height="auto"
          width="70%"
        />
        <p style="color: #FFD150;">NOTE: If any, remove the tool's NPM package</p>
        <br>
        <p>CDN: Install Builderall.appshell (Wrapper)</p>
        <hr><br>
        <b>.com</b>
        <HighCode 
          lang="html"
          codeValue="<script src='https://js.builderall.com/builderall.js'></script>"
          height="auto"
          width="70%"
        /><br>
        <b>.info</b>
        <HighCode 
          lang="html"
          codeValue="<script src='https://appshell.kub.app.builderall.info/appshell.js'></script>"
          height="auto"
          width="70%"
        /><br>
        <b>.io</b>
        <HighCode 
          lang="html"
          codeValue="<script src='https://appshell.kub.app.builderall.io/appshell.js'></script>"
          height="auto"
          width="70%"
        />
        <br><br><br>
        <h5><b>Step 2:</b> Integration (Builderall.css + Builderall.appshell)</h5>
        <br>
        <p>Copy this structure to your project</p>
        <HighCode
          lang="html"
          codeValue="
<body>
    <!-- Builderall.appshell (Wrapper) component -->
    <builderall-appshell tool='your_tool_slug' token='your_token'>

        <!-- Structure with banner, sidebar, ... -->
        <div class='ba-app-content'>
            <!-- Banner -->
            <builderall-banner 
                                    title='Your title'
                                    subtitle='Your subtitle'
                                    description=' Your description'
                            />
            
            <!-- Content -->
            <div class='ba-app-wrapper ba-gp-1'>
                <div class='ba-sidebar'>
                    <ul class='ba-nav'>
                        <li class='ba-nav-item'>
                            <a href='YOUR_LINK' class='ba-link ba-is-active'>
                                YOUR_SVG
                                <span class='ba-title'>Label Text</span>
                            </a>
                        </li>
                        <li class='ba-nav-item'>
                            <a href='YOUR_LINK' class='ba-link'>
                                YOUR_SVG
                                <span class='ba-title'>Label Text</span>
                            </a>
                        </li>
                        <li class='ba-nav-item'>
                            <a href='YOUR_LINK' class='ba-link'>
                                YOUR_SVG
                                <span class='ba-title'>Label Text</span>
                            </a>
                        </li>
                        <li class='ba-nav-item'>
                            <a href='YOUR_LINK' class='ba-link'>
                                YOUR_SVG
                                <span class='ba-title'>Label Text</span>
                            </a>
                        </li>
                    </ul>
                    <div class='ba-bottom'>
                        <ul class='ba-bottom-list'>
                            <li>
                                <builderall-help></builderall-help>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class='ba-container ba-flex ba-gp-1 ba-ai-baseline ba-jc-center ba-f-wrap'>
                    <div class='ba-card'>
                        YOUR CONTENT HERE
                    </div>
                    <div class='ba-card'>
                        YOUR CONTENT HERE
                    </div>
                    <div class='ba-card'>
                        YOUR CONTENT HERE
                    </div>
                </div>
                <div class='ba-footer'>
                    <builderall-footer></builderall-footer>
                </div>
            </div>
        </div>

    </builderall-appshell>
</body>
          "
          height="auto"
          width="1100px"
        />
    </div>
</template>
