<template>
    <div class="flex-grid">
        <h3>Flex &amp; Grid</h3>
        <p>👉🏼 We have multiple elements to use in <b>Flex &amp; Grid</b></p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-flex ba-gp-1</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-flex ba-gp-1'>
    <div></div>
    <div></div>
    <div></div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="box ba-flex ba-gp-1">
                    <div class="box-gp"></div>
                    <div class="box-gp"></div>
                    <div class="box-gp"></div>
                </div>
            </div>
        </div>
        <hr><br>
        <h5>Flex &amp; Grid:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>ba-flex</b></td>
                    <td>display: flex;</td>
                    <td>
                        <div class="box ba-flex ba-gp-1">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-grid</b></td>
                    <td>display: grid;</td>
                    <td>
                        <div class="box ba-grid ba-gp-1">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Gap:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-flex <b>ba-gp-02</b></td>
                    <td>gap: 0.2rem;</td>
                    <td>
                        <div class="box ba-flex ba-gp-02">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-gp-04</b></td>
                    <td>gap: 0.4rem;</td>
                    <td>
                        <div class="box ba-flex ba-gp-04">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-gp-06</b></td>
                    <td>gap: 0.6rem;</td>
                    <td>
                        <div class="box ba-flex ba-gp-06">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-gp-08</b></td>
                    <td>gap: 0.8rem;</td>
                    <td>
                        <div class="box ba-flex ba-gp-08">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-gp-1</b></td>
                    <td>gap: 16px;</td>
                    <td>
                        <div class="box ba-flex ba-gp-1">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-gp-2</b></td>
                    <td>gap: 32px;</td>
                    <td>
                        <div class="box ba-flex ba-gp-2">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-gp-3</b></td>
                    <td>gap: 3rem;</td>
                    <td>
                        <div class="box ba-flex ba-gp-3">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-gp-4</b></td>
                    <td>gap: 4rem;</td>
                    <td>
                        <div class="box ba-flex ba-gp-4">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Align Items:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-ai-center</b></td>
                    <td>align-items: center;</td>
                    <td>
                        <div class="box-ai ba-flex ba-gp-1 ba-ai-center">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-ai-start</b></td>
                    <td>align-items: start;</td>
                    <td>
                        <div class="box-ai ba-flex ba-gp-1 ba-ai-start">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-ai-end</b></td>
                    <td>align-items: end;</td>
                    <td>
                        <div class="box-ai ba-flex ba-gp-1 ba-ai-end">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-ai-baseline</b></td>
                    <td>align-items: baseline;</td>
                    <td>
                        <div class="box-ai ba-flex ba-gp-1 ba-ai-baseline">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Justify Content:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-jc-center</b></td>
                    <td>justify-content: center;</td>
                    <td>
                        <div class="box-jc ba-flex ba-gp-1 ba-jc-center">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-jc-sb</b></td>
                    <td>justify-content: space-between;</td>
                    <td>
                        <div class="box-jc ba-flex ba-gp-1 ba-jc-sb">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-jc-sa</b></td>
                    <td>justify-content: space-around;</td>
                    <td>
                        <div class="box-jc ba-flex ba-jc-sa">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-jc-se</b></td>
                    <td>justify-content: space-evenly;</td>
                    <td>
                        <div class="box-jc ba-flex ba-jc-se">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-jc-fs</b></td>
                    <td>justify-content: flex-start;</td>
                    <td>
                        <div class="box-jc ba-flex ba-jc-fs">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex <b>ba-jc-fe</b></td>
                    <td>justify-content: flex-end;</td>
                    <td>
                        <div class="box-jc ba-flex ba-jc-fe">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Flex Wrap:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-f-wrap</b></td>
                    <td>ba-flex-wrap: wrap;</td>
                    <td>
                        <div class="box-wrap ba-flex ba-gp-1 ba-f-wrap">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-f-nowrap</b></td>
                    <td>ba-flex-wrap: nowrap;</td>
                    <td>
                        <div class="box-wrap ba-flex ba-gp-1 ba-f-nowrap">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Flex Direction:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-f-row</b></td>
                    <td>ba-flex-direction: row;</td>
                    <td>
                        <div class="box ba-flex ba-gp-1 ba-f-row">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-flex ba-gp-1 <b>ba-f-column</b></td>
                    <td>ba-flex-direction: column;</td>
                    <td>
                        <div class="box ba-flex ba-gp-1 ba-f-column">
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                            <div class="box-gp"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="scss">
    @import '../../../scss/index.scss';
    .flex-grid{
        :is(.box,.box-ai,.box-jc,.box-wrap){
            border: 1px solid $light1;
            border-radius: 5px;
            background-color: $skin1;
            width: max-content;
            padding: 16px;
        }
        .box-ai{
            height: 150px;
        }
        .box-jc{
            width: auto;
        }
        .box-gp{
            height: 50px;
            width: 50px;
            border-radius: 5px;
            background-color: $purple1;
        }
        .box-wrap{
            width: 200px;
        }
    }
</style>