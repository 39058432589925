<template>
    <div class="overviews">
        <h3>Overviews</h3>
        <p>👉🏼 We have <b>Overviews</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-f-column">
            <div>
                <p>Type: <b>ba-overview</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-overview'>
    <div class='ba-video-thumb'>
        <img src='https://placehold.co/159x107/000000/AAAAAA' alt='Overview Tools Name'>	
        <button class='ba-button'>
            <builderall-icon code='e6e4' />
        </button>
    </div>
    <span class='ba-title'>Overview Tools Name </span>
    <span class='ba-description'>Here we can insert a text with a written 3-line rule that better justifies the concept of the tool in use and not exceed.</span>
    <button class='ba-btn ba-sm ba-blue ba-w-100'>
        More Info
        <builderall-icon code='c6a4' />
    </button>
</div>
                  "
                  height="auto"
                  width="1400px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-overview">
                    <div class="ba-video-thumb">
                        <img src='https://placehold.co/159x107/000000/AAAAAA' alt='Overview Tools Name'>	
                        <button class="ba-button">
                            <builderall-icon code='e6e4' />
                        </button>
                    </div>
                    <span class="ba-title">Overview Tools Name </span>
                    <span class="ba-description">Here we can insert a text with a written 3-line rule that better justifies the concept of the tool in use and not exceed.</span>
                    <button class='ba-btn ba-sm ba-blue ba-w-100'>
                        More Info
                        <builderall-icon code='c6a4' />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>