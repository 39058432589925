<template>
    <div class="alerts">
        <h3>Alerts</h3>
        <p>👉🏼 We have <b>Alerts</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-alert ba-error'>
    <span>Builderall</span>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-alert ba-error">
                    <span>Builderall</span>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-card-alert ba-info'>
    <p>Are you sure you want to remove marked notes?</p>
    <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
    <div class='ba-checkbox'>
        <input type='checkbox' id='example'>
        <label for='example'>Builderall</label>
    </div>
    <div class='ba-buttons'>
        <button class='ba-btn ba-md'>Label</button>
        <button class='ba-btn ba-md ba-blue'>Label</button>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card-alert ba-info">
                    <p>Are you sure you want to remove marked notes?</p>
                    <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
                    <div class="ba-checkbox">
                        <input type="checkbox" id="example">
                        <label for="example">Builderall</label>
                    </div>
                    <div class="ba-buttons">
                        <button class="ba-btn ba-md">Label</button>
                        <button class="ba-btn ba-md ba-blue">Label</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-alert ba-error'>
    <span>TEXT HERE</span>
    <a href='' class='ba-text-underline'>TEXT HERE</a>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-alert ba-error">
                    <span>Your Biling plan is changing.</span>
                    <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-require-alert ba-error'>
    <div class='ba-pbutton'>
        <span>Oops! Something went wrong.</span>
        <p>TEXT HERE</p>
    </div>    
    <button class='ba-btn ba-sm ba-red'>Label</button>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-require-alert ba-error">
                    <div class="ba-content">
                        <span>Oops! Something went wrong.</span>
                        <p>Please check your connection or try again later, Please check your connection or try again laterPlease check your connection or try again laterPlease check your connection or try again later Please check your connection or try again later</p>
                    </div>    
                    <button class="ba-btn ba-sm ba-red">Label</button>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-top-alert ba-error'>
    <div class='ba-content'>
        <span>Your Biling plan is changing.</span>
        <a href='#' class='ba-text-underline'>Click here to Preview your upcoming bill.</a>
    </div>    
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-top-alert ba-error">
                    <div class="ba-content">
                        <span>Your Biling plan is changing.</span>
                        <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                    </div>    
                </div>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-alert ba-error</td>
                    <td>
                        <div class="ba-alert ba-error">
                            <span>Builderall</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-alert ba-success</td>
                    <td>
                        <div class="ba-alert ba-success">
                            <span>Builderall</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-alert ba-warning</td>
                    <td>
                        <div class="ba-alert ba-warning">
                            <span>Builderall</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-alert ba-info</td>
                    <td>
                        <div class="ba-alert ba-info">
                            <span>Builderall</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-card-alert ba-success</td>
                    <td>
                        <div class="ba-card-alert ba-success">
                            <p>Are you sure you want to remove marked notes?</p>
                            <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
                            <div class="ba-checkbox">
                                <input type="checkbox" id="example">
                                <label for="example">Builderall</label>
                            </div>
                            <div class="ba-buttons">
                                <button class="ba-btn ba-md">Label</button>
                                <button class="ba-btn ba-md ba-green">Label</button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-card-alert ba-error</td>
                    <td>
                        <div class="ba-card-alert ba-error">
                            <p>Are you sure you want to remove marked notes?</p>
                            <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
                            <div class="ba-checkbox">
                                <input type="checkbox" id="example">
                                <label for="example">Builderall</label>
                            </div>
                            <div class="ba-buttons">
                                <button class="ba-btn ba-md">Label</button>
                                <button class="ba-btn ba-md ba-red">Label</button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-card-alert ba-alert-popup</td>
                    <td>
                        <div class="ba-card-alert ba-alert-popup">
                            <p>Are you sure you want to remove marked notes?</p>
                            <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
                            <div class="ba-checkbox">
                                <input type="checkbox" id="example">
                                <label for="example">Builderall</label>
                            </div>
                            <div class="ba-buttons">
                                <button class="ba-btn ba-md">Label</button>
                                <button class="ba-btn ba-md ba-orange">Label</button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-alert ba-error</td>
                    <td>
                        <div class="ba-alert ba-error">
                            <span>Your Biling plan is changing.</span>
                            <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-alert ba-success</td>
                    <td>
                        <div class="ba-alert ba-success">
                            <span>Your Biling plan is changing.</span>
                            <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-alert ba-warning</td>
                    <td>
                        <div class="ba-alert ba-warning">
                            <span>Your Biling plan is changing.</span>
                            <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-alert ba-info</td>
                    <td>
                        <div class="ba-alert ba-info">
                            <span>Your Biling plan is changing.</span>
                            <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-require-alert ba-warning</td>
                    <td>
                        <div class="ba-require-alert ba-warning">
                            <div class="ba-content">
                                <span>Oops! Something went wrong.</span>
                                <p>Please check your connection or try again later, Please check your connection or try again laterPlease check your connection or try again laterPlease check your connection or try again later Please check your connection or try again later</p>
                            </div>    
                            <button class="ba-btn ba-sm ba-orange">Label</button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-require-alert ba-info</td>
                    <td>
                        <div class="ba-require-alert ba-info">
                            <div class="ba-content">
                                <span>Oops! Something went wrong.</span>
                                <p>Please check your connection or try again later, Please check your connection or try again laterPlease check your connection or try again laterPlease check your connection or try again later Please check your connection or try again later</p>
                            </div>    
                            <button class="ba-btn ba-sm ba-blue">Label</button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-top-alert ba-warning</td>
                    <td>
                    <div>
                        <p>Result: </p>
                        <div class="ba-top-alert ba-warning">
                            <div class="ba-content">
                                <span>Your Biling plan is changing.</span>
                                <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                            </div>    
                        </div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-top-alert ba-info</td>
                    <td>
                    <div>
                        <p>Result: </p>
                        <div class="ba-top-alert ba-info">
                            <div class="ba-content">
                                <span>Your Biling plan is changing.</span>
                                <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                            </div>    
                        </div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-top-alert ba-success</td>
                    <td>
                    <div>
                        <p>Result: </p>
                        <div class="ba-top-alert ba-success">
                            <div class="ba-content">
                                <span>Your Biling plan is changing.</span>
                                <a href="#" class="ba-text-underline">Click here to Preview your upcoming bill.</a>
                            </div>    
                        </div>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>