<script setup lang="ts">
import Header from "./components/Header.vue"
import Sidebar from "./components/Sidebar.vue"

</script>

<template>
    <div class="app">
        <Header />
        <div class="content">
            <Sidebar />
            <main>
                <router-view />
            </main>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '../../scss/index.scss';
    .app{
        display: flex;
        flex-direction: column;
        background-color: $light8;
        min-height: 100vh;
        .content{
            display: flex;
            gap: 16px;
            align-content: baseline;
            main{
                padding: 2.4rem;
                width: 100%;
            }
        }
    }
</style>
