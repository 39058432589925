<script setup lang="ts">

const routes = [
    {
        title: 'GitHub',
        route: 'https://github.com/ebusiness4us/builderall.css'
    },
    {
        title: 'NPM',
        route: 'https://www.npmjs.com/package/buiderall.css'
    }
]

</script>

<template>
    <header class="header">
        <h4>Builderall.css</h4>
        <div class="elements">
            <a 
                v-for="(r,i) in routes" 
                :key="i"
                :href="r.route"
                target="_blank"
            >
                {{ r.title }}
            </a>
        </div>
    </header>
</template>

<style scoped lang="scss">
    @import '../../../scss/index.scss';
    .header{
        display: flex;
        justify-content: space-between;
        padding: 0 5rem;
        gap: 16px;
        align-items: center;
        border-bottom: 1px solid $black18;
        min-height: 80px;
        .elements{
            display: flex;
            gap: 16px;
        }
    }
</style>
