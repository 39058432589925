<template>
    <div class="selects">
        <h3>Selects</h3>
        <p>👉🏼 We have <b>Selects</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-select ba-md</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<select class='ba-select ba-md' name='' id=''>
    <option value='all'>All</option>
    <option value='week'>7 days</option>
    <option value='month'>30 days</option>
</select>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <select class="ba-select ba-md" name="" id="">
                    <option value="all">All</option>
                    <option value="week">7 days</option>
                    <option value="month">30 days</option>
                </select>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Size</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-sm</td>
                    <td>
                        <select class="ba-select ba-sm" name="" id="">
                            <option value="all">All</option>
                            <option value="week">7 days</option>
                            <option value="month">30 days</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>
                        <select class="ba-select ba-md" name="" id="">
                            <option value="all">All</option>
                            <option value="week">7 days</option>
                            <option value="month">30 days</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>
                        <select class="ba-select ba-lg" name="" id="">
                            <option value="all">All</option>
                            <option value="week">7 days</option>
                            <option value="month">30 days</option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>