<template>
    <div class="breadcrumbs">
        <h3>Breadcrumbs</h3>
        <p>👉🏼 We have <b>Breadcrumbs</b> to project</p>
        <br>
        <h5>Exemple:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap ba-f-column">
            <div>
                <p>Type: <b>ba-breadcrumb</b></p>
                <HighCode lang="html" codeValue="
<div class='ba-card-breadcrumb ba-card'>
    <nav class='ba-breadcrumb'>
        <ol class='ba-breadcrumb-ol'>
            <li class='ba-breadcrumb-item ba-main-item'>
                <a href='#'>
                    <builderall-icon code='star' />
                </a>
            </li>
            <li class='ba-breadcrumb-item'>
                <a href='#'>Contacts</a>
            </li>
            <li class='ba-breadcrumb-item'>
                <a href='#'>Graphics</a>
            </li>
        </ol>
    </nav>
</div>

                " height="auto" width="900px" />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-card-breadcrumb ba-card">
                    <nav class="ba-breadcrumb">
                        <ol class="ba-breadcrumb-ol">
                            <li class="ba-breadcrumb-item ba-main-item">
                                <a href="#">
                                    <builderall-icon code="house" />
                                </a>
                            </li>
                            <li class="ba-breadcrumb-item">
                                <a href="#">Contacts</a>
                            </li>
                            <li class="ba-breadcrumb-item">
                                <a href="#">Graphics</a>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>