<template>
    <div class="avatars">
        <h3>Avatars</h3>
        <p>👉🏼 We have multiple <b>avatars</b> to use</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-avatar ba-lg</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-avatar ba-lg'>
    <div class='ba-image'>
        <img src='YOUR_PATH' alt=''>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-avatar ba-lg">
                    <div class="ba-image">
                        <img src="../../public/favicon.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-avatar ba-md</b></p>
                <a href="/circles">Click here to show available colors</a>
                <br><br>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-avatar ba-md'>
    <div class='ba-image'>
        <img src='YOUR_PATH' alt=''>
        <span class='ba-circle ba-green'></span>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-avatar ba-md">
                    <div class="ba-image">
                        <img src="../../public/favicon.png" alt="">
                        <span class='ba-circle ba-green'></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-avatar ba-md</b></p>
                <a href="/circles">Click here to show available colors</a>
                <br><br>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-avatar ba-md'>
    <div class='ba-image'>
        <img src='YOUR_PATH' alt=''>
        <span class='ba-circle ba-purple'></span>
    </div>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-avatar ba-md">
                    <div class="ba-image">
                        <img src="../../public/favicon.png" alt="">
                        <span class='ba-circle ba-purple'></span>
                    </div>
                </div>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Main Class</th>
                    <th>Sub Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-avatar ba-sm</td>
                    <td>ba-image</td>
                    <td>
                        <div class="ba-avatar ba-sm">
                            <div class="ba-image">
                                <img src="../../public/favicon.png" alt="">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-md</td>
                    <td>ba-image</td>
                    <td>
                        <div class="ba-avatar ba-md">
                            <div class="ba-image">
                                <img src="../../public/favicon.png" alt="">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-lg</td>
                    <td>ba-image</td>
                    <td>
                        <div class="ba-avatar ba-lg">
                            <div class="ba-image">
                                <img src="../../public/favicon.png" alt="">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-xl</td>
                    <td>ba-image</td>
                    <td>
                        <div class="ba-avatar ba-xl">
                            <div class="ba-image">
                                <img src="../../public/favicon.png" alt="">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-sm</td>
                    <td>ba-image</td>
                    <td>
                        <div class="ba-avatar ba-sm">
                            <div class="ba-image">
                                <img src="../../public/favicon.png" alt="">
                                <span class='ba-circle ba-green'></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-md</td>
                    <td>ba-image</td>
                    <td>
                        <div class="ba-avatar ba-md">
                            <div class="ba-image">
                                <img src="../../public/favicon.png" alt="">
                                <span class='ba-circle ba-green'></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-lg</td>
                    <td>ba-image</td>
                    <td>
                        <div class="ba-avatar ba-lg">
                            <div class="ba-image">
                                <img src="../../public/favicon.png" alt="">
                                <span class='ba-circle ba-green'></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-xl</td>
                    <td>ba-image</td>
                    <td>
                        <div class="ba-avatar ba-xl">
                            <div class="ba-image">
                                <img src="../../public/favicon.png" alt="">
                                <span class='ba-circle ba-green'></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-sm</td>
                    <td>ba-letters</td>
                    <td>
                        <div class="ba-avatar ba-sm">
                            <div class="ba-letters">
                                BA
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-md</td>
                    <td>ba-letters</td>
                    <td>
                        <div class="ba-avatar ba-md">
                            <div class="ba-letters">
                                BA
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-lg</td>
                    <td>ba-letters</td>
                    <td>
                        <div class="ba-avatar ba-lg">
                            <div class="ba-letters">
                                BA
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-xl</td>
                    <td>ba-letters</td>
                    <td>
                        <div class="ba-avatar ba-xl">
                            <div class="ba-letters">
                                BA
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-sm</td>
                    <td>ba-letters</td>
                    <td>
                        <div class="ba-avatar ba-sm">
                            <div class="ba-letters">
                                BA
                                <span class='ba-circle ba-green'></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-md</td>
                    <td>ba-letters</td>
                    <td>
                        <div class="ba-avatar ba-md">
                            <div class="ba-letters">
                                BA
                                <span class='ba-circle ba-green'></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-lg</td>
                    <td>ba-letters</td>
                    <td>
                        <div class="ba-avatar ba-lg">
                            <div class="ba-letters">
                                BA
                                <span class='ba-circle ba-green'></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-avatar ba-xl</td>
                    <td>ba-letters</td>
                    <td>
                        <div class="ba-avatar ba-xl">
                            <div class="ba-letters">
                                BA
                                <span class='ba-circle ba-green'></span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>