<template>
    <div class="badges">
        <h3>Badges</h3>
        <p>👉🏼 We have <b>Badges</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-badge ba-green</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<span class='ba-badge ba-green'>Status</span>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <span class="ba-badge ba-green">Status</span>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-badge</td>
                    <td>
                        <span class="ba-badge">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-green</td>
                    <td>
                        <span class="ba-badge ba-green">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-red</td>
                    <td>
                        <span class="ba-badge ba-red">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-bg-purple</td>
                    <td>
                        <span class="ba-badge ba-bg-purple">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-bg-green</td>
                    <td>
                        <span class="ba-badge ba-bg-green">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-bg-yellow</td>
                    <td>
                        <span class="ba-badge ba-bg-yellow">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-bg-red</td>
                    <td>
                        <span class="ba-badge ba-bg-red">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-bg-gray</td>
                    <td>
                        <span class="ba-badge ba-bg-gray">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-bg-orange</td>
                    <td>
                        <span class="ba-badge ba-bg-orange">Status</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <h5>Sizes:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-badge ba-sm</td>
                    <td>
                        <span class="ba-badge ba-sm">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-md</td>
                    <td>
                        <span class="ba-badge ba-md">Status</span>
                    </td>
                </tr>
                <tr>
                    <td>ba-badge ba-lg</td>
                    <td>
                        <span class="ba-badge ba-lg">Status</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>