<template>
    <div class="colors">
        <h3>Colors</h3>
        <p>👉🏼 We have <b>Colors</b> to project</p>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Variable</th>
                    <th>Bakground Color</th>
                    <th>Color</th>
                    <th>Hover Color</th>
                    <th>Hover Background Color</th>
                    <th>Hex</th>
                    <th>Color</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>$white1</td>
                    <td>ba-bg-white1</td>
                    <td>ba-color-white1</td>
                    <td class="ba-bg-hover-white1">ba-bg-hover-white1</td>
                    <td class="ba-color-hover-white1">ba-color-hover-white1</td>
                    <td class="ba-bg-white1">#fff</td>
                    <td>
                        <HighCode lang="scss" codeValue="$white1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$white2</td>
                    <td>ba-bg-white2</td>
                    <td>ba-color-white2</td>
                    <td class="ba-bg-hover-white2">ba-bg-hover-white2</td>
                    <td class="ba-color-hover-white2">ba-color-hover-white2</td>
                    <td class="ba-bg-white2">#FCFEFF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$white2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue1</td>
                    <td>ba-bg-blue1</td>
                    <td>ba-color-blue1</td>
                    <td class="ba-bg-hover-blue1">ba-bg-hover-blue1</td>
                    <td class="ba-color-hover-blue1">ba-color-hover-blue1</td>
                    <td class="ba-bg-blue1">#0080FC</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue2</td>
                    <td>ba-bg-blue2</td>
                    <td>ba-color-blue2</td>
                    <td class="ba-bg-hover-blue2">ba-bg-hover-blue2</td>
                    <td class="ba-color-hover-blue2">ba-color-hover-blue2</td>
                    <td class="ba-bg-blue2">#0080FC</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue3</td>
                    <td>ba-bg-blue3</td>
                    <td>ba-color-blue3</td>
                    <td class="ba-bg-hover-blue3">ba-bg-hover-blue3</td>
                    <td class="ba-color-hover-blue3">ba-color-hover-blue3</td>
                    <td style="backgroundColor: #1F91FF;">#1F91FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue4</td>
                    <td>ba-bg-blue4</td>
                    <td>ba-color-blue4</td>
                    <td class="ba-bg-hover-blue4">ba-bg-hover-blue4</td>
                    <td class="ba-color-hover-blue4">ba-color-hover-blue4</td>
                    <td style="backgroundColor: #3099FF;">#3099FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue5</td>
                    <td>ba-bg-blue5</td>
                    <td>ba-color-blue5</td>
                    <td class="ba-bg-hover-blue5">ba-bg-hover-blue5</td>
                    <td class="ba-color-hover-blue5">ba-color-hover-blue5</td>
                    <td style="backgroundColor: #41A2FF;">#41A2FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue6</td>
                    <td>ba-bg-blue6</td>
                    <td>ba-color-blue6</td>
                    <td class="ba-bg-hover-blue6">ba-bg-hover-blue6</td>
                    <td class="ba-color-hover-blue6">ba-color-hover-blue6</td>
                    <td style="backgroundColor: #52AAFF;">#52AAFF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue7</td>
                    <td>ba-bg-blue7</td>
                    <td>ba-color-blue7</td>
                    <td class="ba-bg-hover-blue7">ba-bg-hover-blue7</td>
                    <td class="ba-color-hover-blue7">ba-color-hover-blue7</td>
                    <td style="backgroundColor: #63B2FF;">#63B2FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue8</td>
                    <td>ba-bg-blue8</td>
                    <td>ba-color-blue8</td>
                    <td class="ba-bg-hover-blue8">ba-bg-hover-blue8</td>
                    <td class="ba-color-hover-blue8">ba-color-hover-blue8</td>
                    <td style="backgroundColor: #85C3FF;">#85C3FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue9</td>
                    <td>ba-bg-blue9</td>
                    <td>ba-color-blue9</td>
                    <td class="ba-bg-hover-blue9">ba-bg-hover-blue9</td>
                    <td class="ba-color-hover-blue9">ba-color-hover-blue9</td>
                    <td style="backgroundColor: #96CBFF;">#96CBFF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue10</td>
                    <td>ba-bg-blue10</td>
                    <td>ba-color-blue10</td>
                    <td class="ba-bg-hover-blue10">ba-bg-hover-blue10</td>
                    <td class="ba-color-hover-blue10">ba-color-hover-blue10</td>
                    <td style="backgroundColor: #A7D4FF;">#A7D4FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue11</td>
                    <td>ba-bg-blue11</td>
                    <td>ba-color-blue11</td>
                    <td class="ba-bg-hover-blue11">ba-bg-hover-blue11</td>
                    <td class="ba-color-hover-blue11">ba-color-hover-blue11</td>
                    <td style="backgroundColor: #B8DCFF;">#B8DCFF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue12</td>
                    <td>ba-bg-blue12</td>
                    <td>ba-color-blue12</td>
                    <td class="ba-bg-hover-blue12">ba-bg-hover-blue12</td>
                    <td class="ba-color-hover-blue12">ba-color-hover-blue12</td>
                    <td style="backgroundColor: #C9E4FF;">#C9E4FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue12" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue13</td>
                    <td>ba-bg-blue13</td>
                    <td>ba-color-blue13</td>
                    <td class="ba-bg-hover-blue13">ba-bg-hover-blue13</td>
                    <td class="ba-color-hover-blue13">ba-color-hover-blue13</td>
                    <td style="backgroundColor: #DAEDFF;">#DAEDFF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue13" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue14</td>
                    <td>ba-bg-blue14</td>
                    <td>ba-color-blue14</td>
                    <td class="ba-bg-hover-blue14">ba-bg-hover-blue14</td>
                    <td class="ba-color-hover-blue14">ba-color-hover-blue14</td>
                    <td style="backgroundColor: #EBF5FF;">#EBF5FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue14" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue15</td>
                    <td>ba-bg-blue15</td>
                    <td>ba-color-blue15</td>
                    <td class="ba-bg-hover-blue15">ba-bg-hover-blue15</td>
                    <td class="ba-color-hover-blue15">ba-color-hover-blue15</td>
                    <td style="backgroundColor: #5E81F4;">#5E81F4</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue15" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue16</td>
                    <td>ba-bg-blue16</td>
                    <td>ba-color-blue16</td>
                    <td class="ba-bg-hover-blue16">ba-bg-hover-blue16</td>
                    <td class="ba-color-hover-blue16">ba-color-hover-blue16</td>
                    <td style="backgroundColor: #1665D8;">#1665D8</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue16" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue17</td>
                    <td>ba-bg-blue17</td>
                    <td>ba-color-blue17</td>
                    <td class="ba-bg-hover-blue17">ba-bg-hover-blue17</td>
                    <td class="ba-color-hover-blue17">ba-color-hover-blue17</td>
                    <td style="backgroundColor: #FCFEFF;">#FCFEFF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue17" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue18</td>
                    <td>ba-bg-blue18</td>
                    <td>ba-color-blue18</td>
                    <td class="ba-bg-hover-blue18">ba-bg-hover-blue18</td>
                    <td class="ba-color-hover-blue18">ba-color-hover-blue18</td>
                    <td style="backgroundColor: #E4EAF6;">#E4EAF6</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue18" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue19</td>
                    <td>ba-bg-blue19</td>
                    <td>ba-color-blue19</td>
                    <td class="ba-bg-hover-blue19">ba-bg-hover-blue19</td>
                    <td class="ba-color-hover-blue19">ba-color-hover-blue19</td>
                    <td style="backgroundColor: #0072E1;">#0072E1</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue19" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue20</td>
                    <td>ba-bg-blue20</td>
                    <td>ba-color-blue20</td>
                    <td class="ba-bg-hover-blue20">ba-bg-hover-blue20</td>
                    <td class="ba-color-hover-blue20">ba-color-hover-blue20</td>
                    <td style="backgroundColor: #E5F1FC;">#E5F1FC</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue20" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue21</td>
                    <td>ba-bg-blue21</td>
                    <td>ba-color-blue21</td>
                    <td class="ba-bg-hover-blue21">ba-bg-hover-blue21</td>
                    <td class="ba-color-hover-blue21">ba-color-hover-blue21</td>
                    <td style="backgroundColor: #E6EFFA;">#E6EFFA</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue21" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$blue22</td>
                    <td>ba-bg-blue22</td>
                    <td>ba-color-blue22</td>
                    <td class="ba-bg-hover-blue22">ba-bg-hover-blue22</td>
                    <td class="ba-color-hover-blue22">ba-color-hover-blue22</td>
                    <td style="backgroundColor: #F8FAFD;">#F8FAFD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$blue22" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple1</td>
                    <td>ba-bg-purple1</td>
                    <td>ba-color-purple1</td>
                    <td class="ba-bg-hover-purple1">ba-bg-hover-purple1</td>
                    <td class="ba-color-hover-purple1">ba-color-hover-purple1</td>
                    <td style="backgroundColor: #7E1AFD;">#7E1AFD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple2</td>
                    <td>ba-bg-purple2</td>
                    <td>ba-color-purple2</td>
                    <td class="ba-bg-hover-purple2">ba-bg-hover-purple2</td>
                    <td class="ba-color-hover-purple2">ba-color-hover-purple2</td>
                    <td style="backgroundColor: #872BFD;">#872BFD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple3</td>
                    <td>ba-bg-purple3</td>
                    <td>ba-color-purple3</td>
                    <td class="ba-bg-hover-purple3">ba-bg-hover-purple3</td>
                    <td class="ba-color-hover-purple3">ba-color-hover-purple3</td>
                    <td style="backgroundColor: #913CFD;">#913CFD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple4</td>
                    <td>ba-bg-purple4</td>
                    <td>ba-color-purple4</td>
                    <td class="ba-bg-hover-purple4">ba-bg-hover-purple4</td>
                    <td class="ba-color-hover-purple4">ba-color-hover-purple4</td>
                    <td style="backgroundColor: #9A4DFD;">#9A4DFD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple5</td>
                    <td>ba-bg-purple5</td>
                    <td>ba-color-purple5</td>
                    <td class="ba-bg-hover-purple5">ba-bg-hover-purple5</td>
                    <td class="ba-color-hover-purple5">ba-color-hover-purple5</td>
                    <td style="backgroundColor: #A45DFE;">#A45DFE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple6</td>
                    <td>ba-bg-purple6</td>
                    <td>ba-color-purple6</td>
                    <td class="ba-bg-hover-purple6">ba-bg-hover-purple6</td>
                    <td class="ba-color-hover-purple6">ba-color-hover-purple6</td>
                    <td style="backgroundColor: #AD6EFE;">#AD6EFE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple7</td>
                    <td>ba-bg-purple7</td>
                    <td>ba-color-purple7</td>
                    <td class="ba-bg-hover-purple7">ba-bg-hover-purple7</td>
                    <td class="ba-color-hover-purple7">ba-color-hover-purple7</td>
                    <td style="backgroundColor: #B77FFE;">#B77FFE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple8</td>
                    <td>ba-bg-purple8</td>
                    <td>ba-color-purple8</td>
                    <td class="ba-bg-hover-purple8">ba-bg-hover-purple8</td>
                    <td class="ba-color-hover-purple8">ba-color-hover-purple8</td>
                    <td style="backgroundColor: #C090FE;">#C090FE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple9</td>
                    <td>ba-bg-purple9</td>
                    <td>ba-color-purple9</td>
                    <td class="ba-bg-hover-purple9">ba-bg-hover-purple9</td>
                    <td class="ba-color-hover-purple9">ba-color-hover-purple9</td>
                    <td style="backgroundColor: #CAA1FE;">#CAA1FE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple10</td>
                    <td>ba-bg-purple10</td>
                    <td>ba-color-purple10</td>
                    <td class="ba-bg-hover-purple10">ba-bg-hover-purple10</td>
                    <td class="ba-color-hover-purple10">ba-color-hover-purple10</td>
                    <td style="backgroundColor: #D3B2FE;">#D3B2FE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple11</td>
                    <td>ba-bg-purple11</td>
                    <td>ba-color-purple11</td>
                    <td class="ba-bg-hover-purple11">ba-bg-hover-purple11</td>
                    <td class="ba-color-hover-purple11">ba-color-hover-purple11</td>
                    <td style="backgroundColor: #DDC3FE;">#DDC3FE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple12</td>
                    <td>ba-bg-purple12</td>
                    <td>ba-color-purple12</td>
                    <td class="ba-bg-hover-purple12">ba-bg-hover-purple12</td>
                    <td class="ba-color-hover-purple12">ba-color-hover-purple12</td>
                    <td style="backgroundColor: #E6D3FF;">#E6D3FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple12" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple13</td>
                    <td>ba-bg-purple13</td>
                    <td>ba-color-purple13</td>
                    <td class="ba-bg-hover-purple13">ba-bg-hover-purple13</td>
                    <td class="ba-color-hover-purple13">ba-color-hover-purple13</td>
                    <td style="backgroundColor: #F0E4FF;">#F0E4FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple13" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$purple14</td>
                    <td>ba-bg-purple14</td>
                    <td>ba-color-purple14</td>
                    <td class="ba-bg-hover-purple14">ba-bg-hover-purple14</td>
                    <td class="ba-color-hover-purple14">ba-color-hover-purple14</td>
                    <td style="backgroundColor: #F9F5FF;">#F9F5FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$purple14" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan1</td>
                    <td>ba-bg-cyan1</td>
                    <td>ba-color-cyan1</td>
                    <td class="ba-bg-hover-cyan1">ba-bg-hover-cyan1</td>
                    <td class="ba-color-hover-cyan1">ba-color-hover-cyan1</td>
                    <td style="backgroundColor: #00F8F0;">#00F8F0</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan2</td>
                    <td>ba-bg-cyan2</td>
                    <td>ba-color-cyan2</td>
                    <td class="ba-bg-hover-cyan2">ba-bg-hover-cyan2</td>
                    <td class="ba-color-hover-cyan2">ba-color-hover-cyan2</td>
                    <td style="backgroundColor: #1BFFF8;">#1BFFF8</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan3</td>
                    <td>ba-bg-cyan3</td>
                    <td>ba-color-cyan3</td>
                    <td class="ba-bg-hover-cyan3">ba-bg-hover-cyan3</td>
                    <td class="ba-color-hover-cyan3">ba-color-hover-cyan3</td>
                    <td style="backgroundColor: #4EFFF9;">#4EFFF9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan4</td>
                    <td>ba-bg-cyan4</td>
                    <td>ba-color-cyan4</td>
                    <td class="ba-bg-hover-cyan4">ba-bg-hover-cyan4</td>
                    <td class="ba-color-hover-cyan4">ba-color-hover-cyan4</td>
                    <td style="backgroundColor: #70FFFA;">#70FFFA</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan5</td>
                    <td>ba-bg-cyan5</td>
                    <td>ba-color-cyan5</td>
                    <td class="ba-bg-hover-cyan5">ba-bg-hover-cyan5</td>
                    <td class="ba-color-hover-cyan5">ba-color-hover-cyan5</td>
                    <td style="backgroundColor: #81FFFB;">#81FFFB</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan6</td>
                    <td>ba-bg-cyan6</td>
                    <td>ba-color-cyan6</td>
                    <td class="ba-bg-hover-cyan6">ba-bg-hover-cyan6</td>
                    <td class="ba-color-hover-cyan6">ba-color-hover-cyan6</td>
                    <td style="backgroundColor: #92FFFB;">#92FFFB</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan7</td>
                    <td>ba-bg-cyan7</td>
                    <td>ba-color-cyan7</td>
                    <td class="ba-bg-hover-cyan7">ba-bg-hover-cyan7</td>
                    <td class="ba-color-hover-cyan7">ba-color-hover-cyan7</td>
                    <td style="backgroundColor: #B4FFFD;">#B4FFFD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan8</td>
                    <td>ba-bg-cyan8</td>
                    <td>ba-color-cyan8</td>
                    <td class="ba-bg-hover-cyan8">ba-bg-hover-cyan8</td>
                    <td class="ba-color-hover-cyan8">ba-color-hover-cyan8</td>
                    <td style="backgroundColor: #C5FFFD;">#C5FFFD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan9</td>
                    <td>ba-bg-cyan9</td>
                    <td>ba-color-cyan9</td>
                    <td class="ba-bg-hover-cyan9">ba-bg-hover-cyan9</td>
                    <td class="ba-color-hover-cyan9">ba-color-hover-cyan9</td>
                    <td style="backgroundColor: #D6FFFE;">#D6FFFE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan10</td>
                    <td>ba-bg-cyan10</td>
                    <td>ba-color-cyan10</td>
                    <td class="ba-bg-hover-cyan10">ba-bg-hover-cyan10</td>
                    <td class="ba-color-hover-cyan10">ba-color-hover-cyan10</td>
                    <td style="backgroundColor: #D6FFFE;">#D6FFFE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$cyan11</td>
                    <td>ba-bg-cyan11</td>
                    <td>ba-color-cyan11</td>
                    <td class="ba-bg-hover-cyan11">ba-bg-hover-cyan11</td>
                    <td class="ba-color-hover-cyan11">ba-color-hover-cyan11</td>
                    <td style="backgroundColor: #F8FFFF;">#F8FFFF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$cyan11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black1</td>
                    <td>ba-bg-black1</td>
                    <td>ba-color-black1</td>
                    <td class="ba-bg-hover-black1">ba-bg-hover-black1</td>
                    <td class="ba-color-hover-black1">ba-color-hover-black1</td>
                    <td style="backgroundColor: #000;">#000</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black2</td>
                    <td>ba-bg-black2</td>
                    <td>ba-color-black2</td>
                    <td class="ba-bg-hover-black2">ba-bg-hover-black2</td>
                    <td class="ba-color-hover-black2">ba-color-hover-black2</td>
                    <td style="backgroundColor: #131B24;">#131B24</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black3</td>
                    <td>ba-bg-black3</td>
                    <td>ba-color-black3</td>
                    <td class="ba-bg-hover-black3">ba-bg-hover-black3</td>
                    <td class="ba-color-hover-black3">ba-color-hover-black3</td>
                    <td style="backgroundColor: #19232F;">#19232F</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black4</td>
                    <td>ba-bg-black4</td>
                    <td>ba-color-black4</td>
                    <td class="ba-bg-hover-black4">ba-bg-hover-black4</td>
                    <td class="ba-color-hover-black4">ba-color-hover-black4</td>
                    <td style="backgroundColor: #1F2C3A;">#1F2C3A</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black5</td>
                    <td>ba-bg-black5</td>
                    <td>ba-color-black5</td>
                    <td class="ba-bg-hover-black5">ba-bg-hover-black5</td>
                    <td class="ba-color-hover-black5">ba-color-hover-black5</td>
                    <td style="backgroundColor: #253445;">#253445</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black6</td>
                    <td>ba-bg-black6</td>
                    <td>ba-color-black6</td>
                    <td class="ba-bg-hover-black6">ba-bg-hover-black6</td>
                    <td class="ba-color-hover-black6">ba-color-hover-black6</td>
                    <td style="backgroundColor: #2A3C51;">#2A3C51</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black7</td>
                    <td>ba-bg-black7</td>
                    <td>ba-color-black7</td>
                    <td class="ba-bg-hover-black7">ba-bg-hover-black7</td>
                    <td class="ba-color-hover-black7">ba-color-hover-black7</td>
                    <td style="backgroundColor: #30455C;">#30455C</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black8</td>
                    <td>ba-bg-black8</td>
                    <td>ba-color-black8</td>
                    <td class="ba-bg-hover-black8">ba-bg-hover-black8</td>
                    <td class="ba-color-hover-black8">ba-color-hover-black8</td>
                    <td style="backgroundColor: #364D67;">#364D67</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black9</td>
                    <td>ba-bg-black9</td>
                    <td>ba-color-black9</td>
                    <td class="ba-bg-hover-black9">ba-bg-hover-black9</td>
                    <td class="ba-color-hover-black9">ba-color-hover-black9</td>
                    <td style="backgroundColor: #3C5572;">#3C5572</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black10</td>
                    <td>ba-bg-black10</td>
                    <td>ba-color-black10</td>
                    <td class="ba-bg-hover-black10">ba-bg-hover-black10</td>
                    <td class="ba-color-hover-black10">ba-color-hover-black10</td>
                    <td style="backgroundColor: #425E7D;">#425E7D</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black11</td>
                    <td>ba-bg-black11</td>
                    <td>ba-color-black11</td>
                    <td class="ba-bg-hover-black11">ba-bg-hover-black11</td>
                    <td class="ba-color-hover-black11">ba-color-hover-black11</td>
                    <td style="backgroundColor: #486688;">#486688</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black12</td>
                    <td>ba-bg-black12</td>
                    <td>ba-color-black12</td>
                    <td class="ba-bg-hover-black12">ba-bg-hover-black12</td>
                    <td class="ba-color-hover-black12">ba-color-hover-black12</td>
                    <td style="backgroundColor: #4E6E93;">#4E6E93</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black12" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black13</td>
                    <td>ba-bg-black13</td>
                    <td>ba-color-black13</td>
                    <td class="ba-bg-hover-black13">ba-bg-hover-black13</td>
                    <td class="ba-color-hover-black13">ba-color-hover-black13</td>
                    <td style="backgroundColor: #54779E;">#54779E</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black13" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black14</td>
                    <td>ba-bg-black14</td>
                    <td>ba-color-black14</td>
                    <td class="ba-bg-hover-black14">ba-bg-hover-black14</td>
                    <td class="ba-color-hover-black14">ba-color-hover-black14</td>
                    <td style="backgroundColor: #6688AE;">#6688AE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black14" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black15</td>
                    <td>ba-bg-black15</td>
                    <td>ba-color-black15</td>
                    <td class="ba-bg-hover-black15">ba-bg-hover-black15</td>
                    <td class="ba-color-hover-black15">ba-color-hover-black15</td>
                    <td style="backgroundColor: #7191B4;">#7191B4</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black15" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black16</td>
                    <td>ba-bg-black16</td>
                    <td>ba-color-black16</td>
                    <td class="ba-bg-hover-black16">ba-bg-hover-black16</td>
                    <td class="ba-color-hover-black16">ba-color-hover-black16</td>
                    <td style="backgroundColor: #7C99BA;">#7C99BA</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black16" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black17</td>
                    <td>ba-bg-black17</td>
                    <td>ba-color-black17</td>
                    <td class="ba-bg-hover-black17">ba-bg-hover-black17</td>
                    <td class="ba-color-hover-black17">ba-color-hover-black17</td>
                    <td style="backgroundColor: #87A2C0;">#87A2C0</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black17" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black18</td>
                    <td>ba-bg-black18</td>
                    <td>ba-color-black18</td>
                    <td class="ba-bg-hover-black18">ba-bg-hover-black18</td>
                    <td class="ba-color-hover-black18">ba-color-hover-black18</td>
                    <td style="backgroundColor: #92AAC6;">#92AAC6</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black18" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black19</td>
                    <td>ba-bg-black19</td>
                    <td>ba-color-black19</td>
                    <td class="ba-bg-hover-black19">ba-bg-hover-black19</td>
                    <td class="ba-color-hover-black19">ba-color-hover-black19</td>
                    <td style="backgroundColor: #9DB3CC;">#9DB3CC</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black19" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black20</td>
                    <td>ba-bg-black20</td>
                    <td>ba-color-black20</td>
                    <td class="ba-bg-hover-black20">ba-bg-hover-black20</td>
                    <td class="ba-color-hover-black20">ba-color-hover-black20</td>
                    <td style="backgroundColor: #A9BCD1;">#A9BCD1</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black20" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black21</td>
                    <td>ba-bg-black21</td>
                    <td>ba-color-black21</td>
                    <td class="ba-bg-hover-black21">ba-bg-hover-black21</td>
                    <td class="ba-color-hover-black21">ba-color-hover-black21</td>
                    <td style="backgroundColor: #111827;">#111827</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black21" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black22</td>
                    <td>ba-bg-black22</td>
                    <td>ba-color-black22</td>
                    <td class="ba-bg-hover-black22">ba-bg-hover-black22</td>
                    <td class="ba-color-hover-black22">ba-color-hover-black22</td>
                    <td style="backgroundColor: #333333;">#333333</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black22" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$black23</td>
                    <td>ba-bg-black23</td>
                    <td>ba-color-black23</td>
                    <td class="ba-bg-hover-black23">ba-bg-hover-black23</td>
                    <td class="ba-color-hover-black23">ba-color-hover-black23</td>
                    <td style="backgroundColor: #1E1E2D;">#1E1E2D</td>
                    <td>
                        <HighCode lang="scss" codeValue="$black23" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$dark1</td>
                    <td>ba-bg-dark1</td>
                    <td>ba-color-dark1</td>
                    <td class="ba-bg-hover-dark1">ba-bg-hover-dark1</td>
                    <td class="ba-color-hover-dark1">ba-color-hover-dark1</td>
                    <td style="backgroundColor: #646566;">#646566</td>
                    <td>
                        <HighCode lang="scss" codeValue="$dark1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$dark2</td>
                    <td>ba-bg-dark2</td>
                    <td>ba-color-dark2</td>
                    <td class="ba-bg-hover-dark2">ba-bg-hover-dark2</td>
                    <td class="ba-color-hover-dark2">ba-color-hover-dark2</td>
                    <td style="backgroundColor: #97989A;">#97989A</td>
                    <td>
                        <HighCode lang="scss" codeValue="$dark2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$dark3</td>
                    <td>ba-bg-dark3</td>
                    <td>ba-color-dark3</td>
                    <td class="ba-bg-hover-dark3">ba-bg-hover-dark3</td>
                    <td class="ba-color-hover-dark3">ba-color-hover-dark3</td>
                    <td style="backgroundColor: #6E6E6E;">#6E6E6E</td>
                    <td>
                        <HighCode lang="scss" codeValue="$dark3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$dark4</td>
                    <td>ba-bg-dark4</td>
                    <td>ba-color-dark4</td>
                    <td class="ba-bg-hover-dark4">ba-bg-hover-dark4</td>
                    <td class="ba-color-hover-dark4">ba-color-hover-dark4</td>
                    <td style="backgroundColor: #90979F;">#90979F</td>
                    <td>
                        <HighCode lang="scss" codeValue="$dark4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$dark5</td>
                    <td>ba-bg-dark5</td>
                    <td>ba-color-dark5</td>
                    <td class="ba-bg-hover-dark5">ba-bg-hover-dark5</td>
                    <td class="ba-color-hover-dark5">ba-color-hover-dark5</td>
                    <td style="backgroundColor: #4D5D71;">#4D5D71</td>
                    <td>
                        <HighCode lang="scss" codeValue="$dark5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$dark6</td>
                    <td>ba-bg-dark6</td>
                    <td>ba-color-dark6</td>
                    <td class="ba-bg-hover-dark6">ba-bg-hover-dark6</td>
                    <td class="ba-color-hover-dark6">ba-color-hover-dark6</td>
                    <td style="backgroundColor: #8D9EB5;">#8D9EB5</td>
                    <td>
                        <HighCode lang="scss" codeValue="$dark6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba1</td>
                    <td>ba-bg-rgba1</td>
                    <td>ba-color-rgba1</td>
                    <td class="ba-bg-hover-rgba1">ba-bg-hover-rgba1</td>
                    <td class="ba-color-hover-rgba1">ba-color-hover-rgba1</td>
                    <td style="backgroundColor: rgba(0, 0, 0, 0.08);">rgba(0, 0, 0, 0.08)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba2</td>
                    <td>ba-bg-rgba2</td>
                    <td>ba-color-rgba2</td>
                    <td class="ba-bg-hover-rgba2">ba-bg-hover-rgba2</td>
                    <td class="ba-color-hover-rgba2">ba-color-hover-rgba2</td>
                    <td style="backgroundColor: rgba(0, 128, 252, 0.35);">rgba(0, 128, 252, 0.35)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba3</td>
                    <td>ba-bg-rgba3</td>
                    <td>ba-color-rgba3</td>
                    <td class="ba-bg-hover-rgba3">ba-bg-hover-rgba3</td>
                    <td class="ba-color-hover-rgba3">ba-color-hover-rgba3</td>
                    <td style="backgroundColor: rgba(236, 49, 62, 0.35);">rgba(236, 49, 62, 0.35)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba4</td>
                    <td>ba-bg-rgba4</td>
                    <td>ba-color-rgba4</td>
                    <td class="ba-bg-hover-rgba4">ba-bg-hover-rgba4</td>
                    <td class="ba-color-hover-rgba4">ba-color-hover-rgba4</td>
                    <td style="backgroundColor: rgba(0, 199, 174, 0.35);">rgba(0, 199, 174, 0.35)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba5</td>
                    <td>ba-bg-rgba5</td>
                    <td>ba-color-rgba5</td>
                    <td class="ba-bg-hover-rgba5">ba-bg-hover-rgba5</td>
                    <td class="ba-color-hover-rgba5">ba-color-hover-rgba5</td>
                    <td style="backgroundColor: rgba(191, 205, 221, 0.35);">rgba(191, 205, 221, 0.35)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba6</td>
                    <td>ba-bg-rgba6</td>
                    <td>ba-color-rgba6</td>
                    <td class="ba-bg-hover-rgba6">ba-bg-hover-rgba6</td>
                    <td class="ba-color-hover-rgba6">ba-color-hover-rgba6</td>
                    <td style="backgroundColor: rgba(72, 102, 136, 0.35);">rgba(72, 102, 136, 0.35)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba7</td>
                    <td>ba-bg-rgba7</td>
                    <td>ba-color-rgba7</td>
                    <td class="ba-bg-hover-rgba7">ba-bg-hover-rgba7</td>
                    <td class="ba-color-hover-rgba7">ba-color-hover-rgba7</td>
                    <td style="backgroundColor: rgba(24, 39, 75, 0.12);">rgba(24, 39, 75, 0.12)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba8</td>
                    <td>ba-bg-rgba8</td>
                    <td>ba-color-rgba8</td>
                    <td class="ba-bg-hover-rgba8">ba-bg-hover-rgba8</td>
                    <td class="ba-color-hover-rgba8">ba-color-hover-rgba8</td>
                    <td style="backgroundColor: rgba(24, 39, 75, 0.08);">rgba(24, 39, 75, 0.08)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba9</td>
                    <td>ba-bg-rgba9</td>
                    <td>ba-color-rgba9</td>
                    <td class="ba-bg-hover-rgba9">ba-bg-hover-rgba9</td>
                    <td class="ba-color-hover-rgba9">ba-color-hover-rgba9</td>
                    <td style="backgroundColor: rgba(24, 39, 75, 0.1);">rgba(24, 39, 75, 0.1)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba10</td>
                    <td>ba-bg-rgba10</td>
                    <td>ba-color-rgba10</td>
                    <td class="ba-bg-hover-rgba10">ba-bg-hover-rgba10</td>
                    <td class="ba-color-hover-rgba10">ba-color-hover-rgba10</td>
                    <td style="backgroundColor: rgba(24, 39, 75, 0.14);">rgba(24, 39, 75, 0.14)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba11</td>
                    <td>ba-bg-rgba11</td>
                    <td>ba-color-rgba11</td>
                    <td class="ba-bg-hover-rgba11">ba-bg-hover-rgba11</td>
                    <td class="ba-color-hover-rgba11">ba-color-hover-rgba11</td>
                    <td style="backgroundColor: rgba(22, 34, 51, 0.12);">rgba(22, 34, 51, 0.12)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba12</td>
                    <td>ba-bg-rgba12</td>
                    <td>ba-color-rgba12</td>
                    <td class="ba-bg-hover-rgba12">ba-bg-hover-rgba12</td>
                    <td class="ba-color-hover-rgba12">ba-color-hover-rgba12</td>
                    <td style="backgroundColor: rgba(22, 34, 51, 0.16);">rgba(22, 34, 51, 0.16)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba12" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba13</td>
                    <td>ba-bg-rgba13</td>
                    <td>ba-color-rgba13</td>
                    <td class="ba-bg-hover-rgba13">ba-bg-hover-rgba13</td>
                    <td class="ba-color-hover-rgba13">ba-color-hover-rgba13</td>
                    <td style="backgroundColor: rgba(22, 34, 51, 0.08);">rgba(22, 34, 51, 0.08)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba13" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba14</td>
                    <td>ba-bg-rgba14</td>
                    <td>ba-color-rgba14</td>
                    <td class="ba-bg-hover-rgba14">ba-bg-hover-rgba14</td>
                    <td class="ba-color-hover-rgba14">ba-color-hover-rgba14</td>
                    <td style="backgroundColor: rgba(0, 0, 0, 0.11);">rgba(0, 0, 0, 0.11)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba14" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba15</td>
                    <td>ba-bg-rgba15</td>
                    <td>ba-color-rgba15</td>
                    <td class="ba-bg-hover-rgba15">ba-bg-hover-rgba15</td>
                    <td class="ba-color-hover-rgba15">ba-color-hover-rgba15</td>
                    <td style="backgroundColor: rgba(37, 52, 69, 0);">rgba(37, 52, 69, 0)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba15" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba16</td>
                    <td>ba-bg-rgba16</td>
                    <td>ba-color-rgba16</td>
                    <td class="ba-bg-hover-rgba16">ba-bg-hover-rgba16</td>
                    <td class="ba-color-hover-rgba16">ba-color-hover-rgba16</td>
                    <td style="backgroundColor: rgba(0, 114, 225, 0.77);">rgba(0, 114, 225, 0.77)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba16" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$rgba17</td>
                    <td>ba-bg-rgba17</td>
                    <td>ba-color-rgba17</td>
                    <td class="ba-bg-hover-rgba17">ba-bg-hover-rgba17</td>
                    <td class="ba-color-hover-rgba17">ba-color-hover-rgba17</td>
                    <td style="backgroundColor: rgba(0, 0, 0, 0.16);">rgba(0, 0, 0, 0.16)</td>
                    <td>
                        <HighCode lang="scss" codeValue="$rgba17" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light1</td>
                    <td>ba-bg-light1</td>
                    <td>ba-color-light1</td>
                    <td class="ba-bg-hover-light1">ba-bg-hover-light1</td>
                    <td class="ba-color-hover-light1">ba-color-hover-light1</td>
                    <td style="backgroundColor: #B4C4D7;">#B4C4D7</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light2</td>
                    <td>ba-bg-light2</td>
                    <td>ba-color-light2</td>
                    <td class="ba-bg-hover-light2">ba-bg-hover-light2</td>
                    <td class="ba-color-hover-light2">ba-color-hover-light2</td>
                    <td style="backgroundColor: #BFCDDD;">#BFCDDD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light3</td>
                    <td>ba-bg-light3</td>
                    <td>ba-color-light3</td>
                    <td class="ba-bg-hover-light3">ba-bg-hover-light3</td>
                    <td class="ba-color-hover-light3">ba-color-hover-light3</td>
                    <td style="backgroundColor: #CAD6E3;">#CAD6E3</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light4</td>
                    <td>ba-bg-light4</td>
                    <td>ba-color-light4</td>
                    <td class="ba-bg-hover-light4">ba-bg-hover-light4</td>
                    <td class="ba-color-hover-light4">ba-color-hover-light4</td>
                    <td style="backgroundColor: #D5DEE9;">#D5DEE9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light5</td>
                    <td>ba-bg-light5</td>
                    <td>ba-color-light5</td>
                    <td class="ba-bg-hover-light5">ba-bg-hover-light5</td>
                    <td class="ba-color-hover-light5">ba-color-hover-light5</td>
                    <td style="backgroundColor: #E0E7EF;">#E0E7EF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light6</td>
                    <td>ba-bg-light6</td>
                    <td>ba-color-light6</td>
                    <td class="ba-bg-hover-light6">ba-bg-hover-light6</td>
                    <td class="ba-color-hover-light6">ba-color-hover-light6</td>
                    <td style="backgroundColor: #EBF0F5;">#EBF0F5</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light7</td>
                    <td>ba-bg-light7</td>
                    <td>ba-color-light7</td>
                    <td class="ba-bg-hover-light7">ba-bg-hover-light7</td>
                    <td class="ba-color-hover-light7">ba-color-hover-light7</td>
                    <td style="backgroundColor: #F6F8FB;">#F6F8FB</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light8</td>
                    <td>ba-bg-light8</td>
                    <td>ba-color-light8</td>
                    <td class="ba-bg-hover-light8">ba-bg-hover-light8</td>
                    <td class="ba-color-hover-light8">ba-color-hover-light8</td>
                    <td style="backgroundColor: #FAFBFC;">#FAFBFC</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light9</td>
                    <td>ba-bg-light9</td>
                    <td>ba-color-light9</td>
                    <td class="ba-bg-hover-light9">ba-bg-hover-light9</td>
                    <td class="ba-color-hover-light9">ba-color-hover-light9</td>
                    <td style="backgroundColor: #FDFEFE;">#FDFEFE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light10</td>
                    <td>ba-bg-light10</td>
                    <td>ba-color-light10</td>
                    <td class="ba-bg-hover-light10">ba-bg-hover-light10</td>
                    <td class="ba-color-hover-light10">ba-color-hover-light10</td>
                    <td style="backgroundColor: #FFFFFF;">#FFFFFF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light11</td>
                    <td>ba-bg-light11</td>
                    <td>ba-color-light11</td>
                    <td class="ba-bg-hover-light11">ba-bg-hover-light11</td>
                    <td class="ba-color-hover-light11">ba-color-hover-light11</td>
                    <td style="background-color: #DEE3EA;">#DEE3EA</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light12</td>
                    <td>ba-bg-light12</td>
                    <td>ba-color-light12</td>
                    <td class="ba-bg-hover-light12">ba-bg-hover-light12</td>
                    <td class="ba-color-hover-light12">ba-color-hover-light12</td>
                    <td style="background-color: #E9EDF2;">#E9EDF2</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light12" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light13</td>
                    <td>ba-bg-light13</td>
                    <td>ba-color-light13</td>
                    <td class="ba-bg-hover-light13">ba-bg-hover-light13</td>
                    <td class="ba-color-hover-light13">ba-color-hover-light13</td>
                    <td style="background-color: #D9D9D9;">#D9D9D9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light13" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light14</td>
                    <td>ba-bg-light14</td>
                    <td>ba-color-light14</td>
                    <td class="ba-bg-hover-light14">ba-bg-hover-light14</td>
                    <td class="ba-color-hover-light14">ba-color-hover-light14</td>
                    <td style="background-color: #EFF2F8;">#EFF2F8</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light14" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light15</td>
                    <td>ba-bg-light15</td>
                    <td>ba-color-light15</td>
                    <td class="ba-bg-hover-light15">ba-bg-hover-light15</td>
                    <td class="ba-color-hover-light15">ba-color-hover-light15</td>
                    <td style="background-color: #94A4BA;">#94A4BA</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light15" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light16</td>
                    <td>ba-bg-light16</td>
                    <td>ba-color-light16</td>
                    <td class="ba-bg-hover-light16">ba-bg-hover-light16</td>
                    <td class="ba-color-hover-light16">ba-color-hover-light16</td>
                    <td style="background-color: #95ACCC;">#95ACCC</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light16" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light17</td>
                    <td>ba-bg-light17</td>
                    <td>ba-color-light17</td>
                    <td class="ba-bg-hover-light17">ba-bg-hover-light17</td>
                    <td class="ba-color-hover-light17">ba-color-hover-light17</td>
                    <td style="background-color: #dce4ee;">#dce4ee</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light17" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light18</td>
                    <td>ba-bg-light18</td>
                    <td>ba-color-light18</td>
                    <td class="ba-bg-hover-light18">ba-bg-hover-light18</td>
                    <td class="ba-color-hover-light18">ba-color-hover-light18</td>
                    <td style="background-color: #E0F0FF;">#E0F0FF</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light18" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light19</td>
                    <td>ba-bg-light19</td>
                    <td>ba-color-light19</td>
                    <td class="ba-bg-hover-light19">ba-bg-hover-light19</td>
                    <td class="ba-color-hover-light19">ba-color-hover-light19</td>
                    <td style="background-color: #F3F6F9;">#F3F6F9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light19" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light20</td>
                    <td>ba-bg-light20</td>
                    <td>ba-color-light20</td>
                    <td class="ba-bg-hover-light20">ba-bg-hover-light20</td>
                    <td class="ba-color-hover-light20">ba-color-hover-light20</td>
                    <td style="background-color: #D4DFF5;">#D4DFF5</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light20" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light21</td>
                    <td>ba-bg-light21</td>
                    <td>ba-color-light21</td>
                    <td class="ba-bg-hover-light21">ba-bg-hover-light21</td>
                    <td class="ba-color-hover-light21">ba-color-hover-light21</td>
                    <td class="ba-bg-light21">#E1EAF6</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light21" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light22</td>
                    <td>ba-bg-light22</td>
                    <td>ba-color-light22</td>
                    <td class="ba-bg-hover-light22">ba-bg-hover-light22</td>
                    <td class="ba-color-hover-light22">ba-color-hover-light22</td>
                    <td class="ba-bg-light22">#F4F6FB</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light22" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light23</td>
                    <td>ba-bg-light23</td>
                    <td>ba-color-light23</td>
                    <td class="ba-bg-hover-light23">ba-bg-hover-light23</td>
                    <td class="ba-color-hover-light23">ba-color-hover-light23</td>
                    <td class="ba-bg-light23">#66708580</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light23" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$light24</td>
                    <td>ba-bg-light24</td>
                    <td>ba-color-light24</td>
                    <td class="ba-bg-hover-light24">ba-bg-hover-light24</td>
                    <td class="ba-color-hover-light24">ba-color-hover-light24</td>
                    <td class="ba-bg-light24">#C4D1E0</td>
                    <td>
                        <HighCode lang="scss" codeValue="$light24" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green1</td>
                    <td>ba-bg-green1</td>
                    <td>ba-color-green1</td>
                    <td class="ba-bg-hover-green1">ba-bg-hover-green1</td>
                    <td class="ba-color-hover-green1">ba-color-hover-green1</td>
                    <td style="backgroundColor: #20E4B3;">#20E4B3</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green2</td>
                    <td>ba-bg-green2</td>
                    <td>ba-color-green2</td>
                    <td class="ba-bg-hover-green2">ba-bg-hover-green2</td>
                    <td class="ba-color-hover-green2">ba-color-hover-green2</td>
                    <td style="backgroundColor: #2FE6B8;">#2FE6B8</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green3</td>
                    <td>ba-bg-green3</td>
                    <td>ba-color-green3</td>
                    <td class="ba-bg-hover-green3">ba-bg-hover-green3</td>
                    <td class="ba-color-hover-green3">ba-color-hover-green3</td>
                    <td style="backgroundColor: #3EE8BD;">#3EE8BD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green4</td>
                    <td>ba-bg-green4</td>
                    <td>ba-color-green4</td>
                    <td class="ba-bg-hover-green4">ba-bg-hover-green4</td>
                    <td class="ba-color-hover-green4">ba-color-hover-green4</td>
                    <td style="backgroundColor: #4DEAC3;">#4DEAC3</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green5</td>
                    <td>ba-bg-green5</td>
                    <td>ba-color-green5</td>
                    <td class="ba-bg-hover-green5">ba-bg-hover-green5</td>
                    <td class="ba-color-hover-green5">ba-color-hover-green5</td>
                    <td style="backgroundColor: #5DEBC8;">#5DEBC8</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green6</td>
                    <td>ba-bg-green6</td>
                    <td>ba-color-green6</td>
                    <td class="ba-bg-hover-green6">ba-bg-hover-green6</td>
                    <td class="ba-color-hover-green6">ba-color-hover-green6</td>
                    <td style="backgroundColor: #6CEDCD;">#6CEDCD</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green7</td>
                    <td>ba-bg-green7</td>
                    <td>ba-color-green7</td>
                    <td class="ba-bg-hover-green7">ba-bg-hover-green7</td>
                    <td class="ba-color-hover-green7">ba-color-hover-green7</td>
                    <td style="backgroundColor: #7BEFD2;">#7BEFD2</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green8</td>
                    <td>ba-bg-green8</td>
                    <td>ba-color-green8</td>
                    <td class="ba-bg-hover-green8">ba-bg-hover-green8</td>
                    <td class="ba-color-hover-green8">ba-color-hover-green8</td>
                    <td style="backgroundColor: #8AF1D7;">#8AF1D7</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green9</td>
                    <td>ba-bg-green9</td>
                    <td>ba-color-green9</td>
                    <td class="ba-bg-hover-green9">ba-bg-hover-green9</td>
                    <td class="ba-color-hover-green9">ba-color-hover-green9</td>
                    <td style="backgroundColor: #99F3DC;">#99F3DC</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green10</td>
                    <td>ba-bg-green10</td>
                    <td>ba-color-green10</td>
                    <td class="ba-bg-hover-green10">ba-bg-hover-green10</td>
                    <td class="ba-color-hover-green10">ba-color-hover-green10</td>
                    <td style="backgroundColor: #A8F5E2;">#A8F5E2</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green11</td>
                    <td>ba-bg-green11</td>
                    <td>ba-color-green11</td>
                    <td class="ba-bg-hover-green11">ba-bg-hover-green11</td>
                    <td class="ba-color-hover-green11">ba-color-hover-green11</td>
                    <td style="backgroundColor: #B8F6E7;">#B8F6E7</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green12</td>
                    <td>ba-bg-green12</td>
                    <td>ba-color-green12</td>
                    <td class="ba-bg-hover-green12">ba-bg-hover-green12</td>
                    <td class="ba-color-hover-green12">ba-color-hover-green12</td>
                    <td style="backgroundColor: #C7F8EC;">#C7F8EC</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green12" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green13</td>
                    <td>ba-bg-green13</td>
                    <td>ba-color-green13</td>
                    <td class="ba-bg-hover-green13">ba-bg-hover-green13</td>
                    <td class="ba-color-hover-green13">ba-color-hover-green13</td>
                    <td style="backgroundColor: #D6FAF1;">#D6FAF1</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green13" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green14</td>
                    <td>ba-bg-green14</td>
                    <td>ba-color-green14</td>
                    <td class="ba-bg-hover-green14">ba-bg-hover-green14</td>
                    <td class="ba-color-hover-green14">ba-color-hover-green14</td>
                    <td style="backgroundColor: #E5FCF6;">#E5FCF6</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green14" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green15</td>
                    <td>ba-bg-green15</td>
                    <td>ba-color-green15</td>
                    <td class="ba-bg-hover-green15">ba-bg-hover-green15</td>
                    <td class="ba-color-hover-green15">ba-color-hover-green15</td>
                    <td style="backgroundColor: #F4FEFB;">#F4FEFB</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green15" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green16</td>
                    <td>ba-bg-green16</td>
                    <td>ba-color-green16</td>
                    <td class="ba-bg-hover-green16">ba-bg-hover-green16</td>
                    <td class="ba-color-hover-green16">ba-color-hover-green16</td>
                    <td style="backgroundColor: #1AD9A9;">#1AD9A9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green16" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green17</td>
                    <td>ba-bg-green17</td>
                    <td>ba-color-green17</td>
                    <td class="ba-bg-hover-green17">ba-bg-hover-green17</td>
                    <td class="ba-color-hover-green17">ba-color-hover-green17</td>
                    <td style="backgroundColor: #a1c3a5;">#a1c3a5</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green17" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green18</td>
                    <td>ba-bg-green18</td>
                    <td>ba-color-green18</td>
                    <td class="ba-bg-hover-green18">ba-bg-hover-green18</td>
                    <td class="ba-color-hover-green18">ba-color-hover-green18</td>
                    <td style="backgroundColor: #EAF7EE;">#EAF7EE</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green18" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green19</td>
                    <td>ba-bg-green19</td>
                    <td>ba-color-green19</td>
                    <td class="ba-bg-hover-green19">ba-bg-hover-green19</td>
                    <td class="ba-color-hover-green19">ba-color-hover-green19</td>
                    <td style="backgroundColor: #01D4B9;">#01D4B9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green19" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$green20</td>
                    <td>ba-bg-green20</td>
                    <td>ba-color-green20</td>
                    <td class="ba-bg-hover-green20">ba-bg-hover-green20</td>
                    <td class="ba-color-hover-green20">ba-color-hover-green20</td>
                    <td class="ba-bg-hover-green20">#01D4B9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$green20" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red1</td>
                    <td>ba-bg-red1</td>
                    <td>ba-color-red1</td>
                    <td class="ba-bg-hover-red1">ba-bg-hover-red1</td>
                    <td class="ba-color-hover-red1">ba-color-hover-red1</td>
                    <td style="backgroundColor: #FA4B57;">#FA4B57</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red2</td>
                    <td>ba-bg-red2</td>
                    <td>ba-color-red2</td>
                    <td class="ba-bg-hover-red2">ba-bg-hover-red2</td>
                    <td class="ba-color-hover-red2">ba-color-hover-red2</td>
                    <td style="backgroundColor: #FA5C66;">#FA5C66</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red3</td>
                    <td>ba-bg-red3</td>
                    <td>ba-color-red3</td>
                    <td class="ba-bg-hover-red3">ba-bg-hover-red3</td>
                    <td class="ba-color-hover-red3">ba-color-hover-red3</td>
                    <td style="backgroundColor: #FB6C76;">#FB6C76</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red4</td>
                    <td>ba-bg-red4</td>
                    <td>ba-color-red4</td>
                    <td class="ba-bg-hover-red4">ba-bg-hover-red4</td>
                    <td class="ba-color-hover-red4">ba-color-hover-red4</td>
                    <td style="backgroundColor: #FB7D85;">#FB7D85</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red5</td>
                    <td>ba-bg-red5</td>
                    <td>ba-color-red5</td>
                    <td class="ba-bg-hover-red5">ba-bg-hover-red5</td>
                    <td class="ba-color-hover-red5">ba-color-hover-red5</td>
                    <td style="backgroundColor: #FC8D95;">#FC8D95</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red6</td>
                    <td>ba-bg-red6</td>
                    <td>ba-color-red6</td>
                    <td class="ba-bg-hover-red6">ba-bg-hover-red6</td>
                    <td class="ba-color-hover-red6">ba-color-hover-red6</td>
                    <td style="backgroundColor: #FC9EA4;">#FC9EA4</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red7</td>
                    <td>ba-bg-red7</td>
                    <td>ba-color-red7</td>
                    <td class="ba-bg-hover-red7">ba-bg-hover-red7</td>
                    <td class="ba-color-hover-red7">ba-color-hover-red7</td>
                    <td style="backgroundColor: #FDAEB4;">#FDAEB4</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red8</td>
                    <td>ba-bg-red8</td>
                    <td>ba-color-red8</td>
                    <td class="ba-bg-hover-red8">ba-bg-hover-red8</td>
                    <td class="ba-color-hover-red8">ba-color-hover-red8</td>
                    <td style="backgroundColor: #FDBFC3;">#FDBFC3</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red9</td>
                    <td>ba-bg-red9</td>
                    <td>ba-color-red9</td>
                    <td class="ba-bg-hover-red9">ba-bg-hover-red9</td>
                    <td class="ba-color-hover-red9">ba-color-hover-red9</td>
                    <td style="backgroundColor: #FECFD3;">#FECFD3</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red10</td>
                    <td>ba-bg-red10</td>
                    <td>ba-color-red10</td>
                    <td class="ba-bg-hover-red10">ba-bg-hover-red10</td>
                    <td class="ba-color-hover-red10">ba-color-hover-red10</td>
                    <td style="backgroundColor: #FEE0E2;">#FEE0E2</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red11</td>
                    <td>ba-bg-red11</td>
                    <td>ba-color-red11</td>
                    <td class="ba-bg-hover-red11">ba-bg-hover-red11</td>
                    <td class="ba-color-hover-red11">ba-color-hover-red11</td>
                    <td style="backgroundColor: #FFF0F1;">#FFF0F1</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red12</td>
                    <td>ba-bg-red12</td>
                    <td>ba-color-red12</td>
                    <td class="ba-bg-hover-red12">ba-bg-hover-red12</td>
                    <td class="ba-color-hover-red12">ba-color-hover-red12</td>
                    <td style="backgroundColor: #EC313E;">#EC313E</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red12" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red13</td>
                    <td>ba-bg-red13</td>
                    <td>ba-color-red13</td>
                    <td class="ba-bg-hover-red13">ba-bg-hover-red13</td>
                    <td class="ba-color-hover-red13">ba-color-hover-red13</td>
                    <td style="backgroundColor: #FCEDE9;">#FCEDE9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red13" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red14</td>
                    <td>ba-bg-red14</td>
                    <td>ba-color-red14</td>
                    <td class="ba-bg-hover-red14">ba-bg-hover-red14</td>
                    <td class="ba-color-hover-red14">ba-color-hover-red14</td>
                    <td style="backgroundColor: #FEE4E7;">#FEE4E7</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red14" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$red15</td>
                    <td>ba-bg-red15</td>
                    <td>ba-color-red15</td>
                    <td class="ba-bg-hover-red15">ba-bg-hover-red15</td>
                    <td class="ba-color-hover-red15">ba-color-hover-red15</td>
                    <td class="ba-bg-hover-red15">#FF3636</td>
                    <td>
                        <HighCode lang="scss" codeValue="$red15" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow1</td>
                    <td>ba-bg-yellow1</td>
                    <td>ba-color-yellow1</td>
                    <td class="ba-bg-hover-yellow1">ba-bg-hover-yellow1</td>
                    <td class="ba-color-hover-yellow1">ba-color-hover-yellow1</td>
                    <td style="backgroundColor: #FFCC3F;">#FFCC3F</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow2</td>
                    <td>ba-bg-yellow2</td>
                    <td>ba-color-yellow2</td>
                    <td class="ba-bg-hover-yellow2">ba-bg-hover-yellow2</td>
                    <td class="ba-color-hover-yellow2">ba-color-hover-yellow2</td>
                    <td style="backgroundColor: #FFD150;">#FFD150</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow2" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow3</td>
                    <td>ba-bg-yellow3</td>
                    <td>ba-color-yellow3</td>
                    <td class="ba-bg-hover-yellow3">ba-bg-hover-yellow3</td>
                    <td class="ba-color-hover-yellow3">ba-color-hover-yellow3</td>
                    <td style="backgroundColor: #FFD561;">#FFD561</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow3" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow4</td>
                    <td>ba-bg-yellow4</td>
                    <td>ba-color-yellow4</td>
                    <td class="ba-bg-hover-yellow4">ba-bg-hover-yellow4</td>
                    <td class="ba-color-hover-yellow4">ba-color-hover-yellow4</td>
                    <td style="backgroundColor: #FFE272;">#FFE272</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow4" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow5</td>
                    <td>ba-bg-yellow5</td>
                    <td>ba-color-yellow5</td>
                    <td class="ba-bg-hover-yellow5">ba-bg-hover-yellow5</td>
                    <td class="ba-color-hover-yellow5">ba-color-hover-yellow5</td>
                    <td style="backgroundColor: #FFE783;">#FFE783</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow5" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow6</td>
                    <td>ba-bg-yellow6</td>
                    <td>ba-color-yellow6</td>
                    <td class="ba-bg-hover-yellow6">ba-bg-hover-yellow6</td>
                    <td class="ba-color-hover-yellow6">ba-color-hover-yellow6</td>
                    <td style="backgroundColor: #FFF193;">#FFF193</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow6" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow7</td>
                    <td>ba-bg-yellow7</td>
                    <td>ba-color-yellow7</td>
                    <td class="ba-bg-hover-yellow7">ba-bg-hover-yellow7</td>
                    <td class="ba-color-hover-yellow7">ba-color-hover-yellow7</td>
                    <td style="backgroundColor: #FFF6A4;">#FFF6A4</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow7" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow8</td>
                    <td>ba-bg-yellow8</td>
                    <td>ba-color-yellow8</td>
                    <td class="ba-bg-hover-yellow8">ba-bg-hover-yellow8</td>
                    <td class="ba-color-hover-yellow8">ba-color-hover-yellow8</td>
                    <td style="backgroundColor: #FFFBB5;">#FFFBB5</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow8" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow9</td>
                    <td>ba-bg-yellow9</td>
                    <td>ba-color-yellow9</td>
                    <td class="ba-bg-hover-yellow9">ba-bg-hover-yellow9</td>
                    <td class="ba-color-hover-yellow9">ba-color-hover-yellow9</td>
                    <td style="backgroundColor: #FFFEC6;">#FFFEC6</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow9" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow10</td>
                    <td>ba-bg-yellow10</td>
                    <td>ba-color-yellow10</td>
                    <td class="ba-bg-hover-yellow10">ba-bg-hover-yellow10</td>
                    <td class="ba-color-hover-yellow10">ba-color-hover-yellow10</td>
                    <td style="backgroundColor: #FFFFD7;">#FFFFD7</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow10" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow11</td>
                    <td>ba-bg-yellow11</td>
                    <td>ba-color-yellow11</td>
                    <td class="ba-bg-hover-yellow11">ba-bg-hover-yellow11</td>
                    <td class="ba-color-hover-yellow11">ba-color-hover-yellow11</td>
                    <td style="background-color: #FFF9E9;">#FFF9E9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow11" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow12</td>
                    <td>ba-bg-yellow12</td>
                    <td>ba-color-yellow12</td>
                    <td class="ba-bg-hover-yellow12">ba-bg-hover-yellow12</td>
                    <td class="ba-color-hover-yellow12">ba-color-hover-yellow12</td>
                    <td style="background-color: #FFFEFA;">#FFFEFA</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow12" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$yellow13</td>
                    <td>ba-bg-yellow13</td>
                    <td>ba-color-yellow13</td>
                    <td class="ba-bg-hover-yellow13">ba-bg-hover-yellow13</td>
                    <td class="ba-color-hover-yellow13">ba-color-hover-yellow13</td>
                    <td style="background-color: #FDF6E9;">#FDF6E9</td>
                    <td>
                        <HighCode lang="scss" codeValue="$yellow13" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$orange1</td>
                    <td>ba-bg-orange1</td>
                    <td>ba-color-orange1</td>
                    <td style="background-color: #EE9500;">#EE9500</td>
                    <td>
                        <HighCode lang="scss" codeValue="$orange1" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$orange2</td>
                    <td>ba-bg-orange2</td>
                    <td>ba-color-orange2</td>
                    <td style="background-color: #e39a26;">#E39A26</td>
                    <td>
                        <HighCode lang="scss" codeValue="$orange2" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$skin1</td>
                    <td>ba-bg-skin1</td>
                    <td>ba-color-skin1</td>
                    <td class="ba-bg-hover-skin1">ba-bg-hover-skin1</td>
                    <td class="ba-color-hover-skin1">ba-color-hover-skin1</td>
                    <td style="background-color: #f7cb9c;">#f7cb9c</td>
                    <td>
                        <HighCode lang="scss" codeValue="$skin1" width="300px" height="60px" />
                    </td>
                </tr>
                <tr>
                    <td>$orange1</td>
                    <td>ba-bg-orange1</td>
                    <td>ba-color-orange1</td>
                    <td class="ba-bg-hover-orange1">ba-bg-hover-orange1</td>
                    <td class="ba-color-hover-orange1">ba-color-hover-orange1</td>
                    <td class="ba-bg-orange1">#EE8D00</td>
                    <td>
                        <HighCode lang="scss" codeValue="$orange1" width="300px" height="60px" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>