<template>
    <div class="tabs">
        <h3>Tabs</h3>
        <p>👉🏼 We have <b>Tabs</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-tabs</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-tabs'>
    <a href='#' class='ba-tab'>Today</a>
    <a href='#' class='ba-tab'>Month</a>
    <a href='#' class='ba-tab ba-is-active'>Week</a>
    <a href='#' class='ba-tab'>Day</a>
</div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                    <div class="ba-box-tabs">
                        <a href="#" class="ba-tab">Today</a>
                        <a href="#" class="ba-tab">Month</a>
                        <a href="#" class="ba-tab ba-is-active">Week</a>
                        <a href="#" class="ba-tab">Day</a>
                    </div>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-box-tabs</td>
                    <td>
                        <div class="ba-box-tabs">
                            <a href="#" class="ba-tab ba-is-active">Today</a>
                            <a href="#" class="ba-tab">Month</a>
                            <a href="#" class="ba-tab">Week</a>
                            <a href="#" class="ba-tab">Day</a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>