import { createApp } from 'vue'
import './scss/index.scss'
import router from "./router"
import App from './App.vue'
import 'vue-highlight-code/dist/style.css';
import { HighCode } from 'vue-highlight-code';

createApp(App)
    .use(router)
    .component('HighCode', HighCode)
    .mount('#app')