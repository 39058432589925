<template>
    <div class="banners">
        <h3>Banners</h3>
        <p>👉🏼 We have <b>Banners</b> to project</p>
        <br>
        <h5>Examples:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-ai-center ba-f-column">
            <p class="ba-w-100">Normal Banner - Type: <b>ba-banner</b></p>
            <div>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-banner'>
    <input type='checkbox' class='ba-input-status' id='ba-toggle-info'>
    <div class='ba-header'>
        <div class='ba-header-left'>
            <span class='ba-icon'>YOUR_ICON</span>
            <span class='ba-span'>Name Tools</span>
        </div>
        <label class='ba-label' for='ba-toggle-info'></label>
    </div>
    <div class='ba-tool-description'>
        <span class='ba-span'>Title your for tools here!!!</span>
        <span class='ba-span'>Subtitle for here</span>
    </div>
</div>
                  "
                  height="auto"
                  width="800px"
                />
            </div>
            <br><br>
            <div class="ba-w-100">
                <p>Result: </p>
                <div class="ba-banner">
                    <input type="checkbox" class="ba-input-status" id="ba-toggle-info">
                    <div class="ba-header">
                        <div class="ba-header-left">
                            <span class="ba-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-asterisk" viewBox="0 0 16 16">
                                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
                                </svg>
                            </span>
                            <span class="ba-span">Name Tools</span>
                        </div>
                        <label class="ba-label" for="ba-toggle-info"></label>
                    </div>
                    <div class="ba-tool-description">
                        <span class="ba-span">Title your for tools here!!!</span>
                        <span class="ba-span">Subtitle for here</span>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-ai-center ba-f-column">
            <p class="ba-w-100">Banner with image - Type: <b>ba-banner</b></p>
            <div>
                <HighCode
                  lang="css"
                  codeValue="
.ba-banner{
    background-image: url('your_image');
}
                  "
                  height="auto"
                  width="800px"
                />
            </div>
            <br><br>
            <div class="ba-w-100">
                <p>Result: </p>
                <div class="ba-banner ba-banner-img">
                    <input type="checkbox" class="ba-input-status" id="ba-toggle-info">
                    <div class="ba-header">
                        <div class="ba-header-left">
                            <span class="ba-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-asterisk" viewBox="0 0 16 16">
                                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
                                </svg>
                            </span>
                            <span class="ba-span">Name Tools</span>
                        </div>
                        <label class="ba-label" for="ba-toggle-info"></label>
                    </div>
                    <div class="ba-tool-description">
                        <span class="ba-span">Title your for tools here!!!</span>
                        <span class="ba-span">Subtitle for here</span>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-ai-center ba-f-column">
            <p class="ba-w-100">Banner with message | Type: <b>ba-banner</b></p>
            <div>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-banner'>
    <input type='checkbox' class='ba-input-status' id='ba-toggle-info'>
    <div class='ba-header'>
        <img src='your_image' alt='' class='ba-image'>
        <div class='ba-header-left'>
            <span class='ba-icon'>YOUR_ICON</span>
            <span class='ba-span'>Name Tools</span>
        </div>
        <div class='ba-toast ba-alert'>
            <div class='ba-left'>
                <span class='ba-icon'></span>
                <span class='ba-text'>Mensagem</span>
            </div>
            <span class='ba-close-btn'></span>
        </div>
        <label class='ba-label' for='ba-toggle-info'></label>
    </div>
    <div class='ba-tool-description'>
        <span class='ba-span'>Title your for tools here!!!</span>
        <span class='ba-span'>Subtitle for here</span>
    </div>
</div>
                  "
                  height="auto"
                  width="800px"
                />
            </div>
            <br><br>
            <div class="ba-w-100">
                <p>Result: </p>
                <div class="ba-banner">
                    <input type="checkbox" class="ba-input-status" id="ba-toggle-info2">
                    <div class="ba-header">
                        <div class="ba-header-left">
                            <span class="ba-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-asterisk" viewBox="0 0 16 16">
                                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
                                </svg>
                            </span>
                            <span class="ba-span">Name Tools</span>
                        </div>
                        <div class='ba-toast ba-alert'>
                            <div class='ba-left'>
                                <span class='ba-icon'></span>
                                <span class='ba-text'>Mensagem</span>
                            </div>
                            <span class='ba-close-btn'></span>
                        </div>
                        <label class="ba-label" for="ba-toggle-info2"></label>
                    </div>
                    <div class="ba-tool-description">
                        <span class="ba-span">Title your for tools here!!!</span>
                        <span class="ba-span">Subtitle for here</span>
                    </div>
                </div>
            </div>
        </div>
        <hr><br>
        <h5>Helpers:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Option</th>
                    <th>How to do</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>opened</td>
                    <td>
                        <HighCode
                            lang="html"
                            codeValue="
<input type='checkbox' class='ba-input-status' id='ba-toggle-info'>
                            "
                            height="auto"
                            width="700px"
                        />
                    </td>
                </tr>
                <tr>
                    <td>closed</td>
                    <td>
                        <HighCode
                            lang="html"
                            codeValue="
<input type='checkbox' checked class='ba-input-status' id='ba-toggle-info'>
                            "
                            height="auto"
                            width="800px"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
    .ba-banner-img{
        background-image: url('https://png.pngtree.com/background/20210710/original/pngtree-tech-blue-geometric-glare-banner-picture-image_1059861.jpg');
    }
</style>