<template>
    <div class="progress">
        <h3>Progress</h3>
        <p>👉🏼 We have <b>Progress</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>complete</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<a class='ba-progress'>
    <span class='ba-progress-title'>Subscribers</span>
    <progress class='ba-progress-bar' value='8' max='15'></progress>
    <div class='ba-progress-details'>
        <span class='ba-progress-data'>Example</span>
        <span class='ba-progress-data'>8 of 15</span>
    </div>
</a>
                  "
                  height="auto"
                  width="680px"
                />
            </div>
            <div>
                <p>Result: </p>
                <a class="ba-progress">
                    <span class="ba-progress-title">Subscribers</span>
                    <progress class="ba-progress-bar" value="8" max="15"></progress>
                    <div class="ba-progress-details">
                        <span class="ba-progress-data">Example</span>
                        <span class="ba-progress-data">8 of 15</span>
                    </div>
                </a>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>only ba-progress-bar</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<a class='ba-progress'>
    <progress class='ba-progress-bar' value='8' max='15'></progress>
</a>
                  "
                  height="auto"
                  width="680px"
                />
            </div>
            <div>
                <p>Result: </p>
                <a class="ba-progress">
                    <progress class="ba-progress-bar" value="8" max="15"></progress>
                </a>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>title + ba-progress-bar + data</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<a class='ba-progress'>
    <span class='ba-progress-title'>Subscribers</span>
    <progress class='ba-progress-bar' value='8' max='15'></progress>
    <div class='ba-progress-details'>
        <span class='ba-progress-data'>8 of 15</span>
    </div>
</a>
                  "
                  height="auto"
                  width="680px"
                />
            </div>
            <div>
                <p>Result: </p>
                <a class="ba-progress">
                    <span class="ba-progress-title">Subscribers</span>
                    <progress class="ba-progress-bar" value="8" max="15"></progress>
                    <div class="ba-progress-details">
                        <span class="ba-progress-data">8 of 15</span>
                    </div>
                </a>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>title + ba-progress-bar</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<a class='ba-progress'>
    <span class='ba-progress-title'>Subscribers</span>
    <progress class='ba-progress-bar' value='8' max='15'></progress>
</a>
                  "
                  height="auto"
                  width="680px"
                />
            </div>
            <div>
                <p>Result: </p>
                <a class="ba-progress">
                    <span class="ba-progress-title">Subscribers</span>
                    <progress class="ba-progress-bar" value="8" max="15"></progress>
                </a>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>title + ba-progress-bar + ba-limit-alert</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<a class='ba-progress'>
    <span class='ba-progress-title ba-limit-alert'>Subscribers</span>
    <progress class='ba-progress-bar' value='15' max='10'></progress>
</a>
                  "
                  height="auto"
                  width="680px"
                />
            </div>
            <div>
                <p>Result: </p>
                <a class="ba-progress">
                    <span class="ba-progress-title">Subscribers</span>
                    <progress class="ba-progress-bar ba-limit-alert" value="8" max="10"></progress>
                </a>
            </div>
        </div>
    </div>
</template>

<style>
    .ba-progress{
        width: 200px !important;
    }
</style>