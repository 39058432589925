<template>
    <div class="icons">
        <h3>Icons</h3>
        <p>👉🏼 We have <b>icons</b> webcomponent to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="html"
                  codeValue="
<builderall-icon code='ICON_NAME' />
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <builderall-icon code='star' />
            </div>
        </div>
        <br>
        <h5>Variables:</h5>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="css"
                  codeValue="
builderall-icon{
    --builderall-icon-size: 50px;
    --builderall-icon-color: green;
}
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <builderall-icon class="variation" code='star' />
            </div>
        </div>
        <br>
        <h5>Hover effect:</h5>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="css"
                  codeValue="
builderall-icon:hover{
    --builderall-icon-color: red;
}
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <builderall-icon class="hover" code='star' />
            </div>
        </div>
        <hr><br>
        <h5>Icons:</h5>
        <br>
        <div class="icons-grid">
            <div class="out-box" v-for="i,y in iconsName" :key="y" @click="copyName(i)">
                <div class="inside-box">
                    <builderall-icon :code='i' />
                </div>
                {{ i }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                iconsName: [
                    "folder",
                    "settings",
                    "star",
                    "funnel",
                    "linkedin",
                    "facebook",
                    "twitter",
                    "arrow-left",
                    "arrow-right",
                    "arrow-up",
                    "arrow-down",
                    "house",
                    "search",
                    "trash",
                    "instagram",
                    "share",
                    "cloud",
                    "calendar",
                    "help",
                    "edit",
                    "eye",
                    "user",
                    "fire",
                    "info",
                    "clock",
                    "sitebuilder",
                    "3d-photo-editor-studio",
                    "booking",
                    "browser-notification",
                    "builderall-full",
                    "builderall-logo",
                    "clickmap",
                    "elearning",
                    "email-marketing-5",
                    "forms-types-itens",
                    "helpdesk",
                    "integration",
                    "magazine-builder",
                    "messenger-chatbot",
                    "mockup-studio",
                    "quiz",
                    "seo-report",
                    "sms",
                    "social-autopost",
                    "social-proof-pop-up",
                    "team-access",
                    "telegram",
                    "tools-email-engage",
                    "url-shortener",
                    "video-hosting",
                    "website-agency",
                    "website-chatbot",
                    "wordpress",
                    "new-twitter",
                    "arrow-right-bottom",
                    "arrow-right-top",
                    "calculator",
                    "earnings",
                    "filter",
                    "leads",
                    "message-group",
                    "money-bag",
                    "ranking",
                    "requested",
                    "start",
                    "team",
                    "bell",
                    "code-of-ethics",
                    "notice-board",
                    "become-associate",
                    "team-awesome",
                    "your-ambassador",
                    "hyperlink",
                    "tree",
                    "4faa",
                    "74f2",
                    "d2a0",
                    "dc4e",
                    "e2ae",
                    "044c",
                    "0641",
                    "0715",
                    "075a",
                    "075e",
                    "0a71",
                    "0d80",
                    "0e29",
                    "12cd",
                    "1468",
                    "183b",
                    "195d",
                    "1af6",
                    "1c32",
                    "1c86",
                    "1f43",
                    "1fcf",
                    "1fea",
                    "213d",
                    "22b9",
                    "2425",
                    "2585",
                    "2659",
                    "2780",
                    "280c",
                    "2891",
                    "2b77",
                    "2bc8",
                    "2c64",
                    "3006",
                    "3098",
                    "321b",
                    "3299",
                    "3300",
                    "34a2",
                    "3527",
                    "3596",
                    "3791",
                    "37da",
                    "3a1e",
                    "3b59",
                    "3cd8",
                    "3e70",
                    "43bd",
                    "43e1",
                    "4446",
                    "4788",
                    "4831",
                    "4970",
                    "4c44",
                    "4cec",
                    "4d07",
                    "4d94",
                    "4f18",
                    "5151",
                    "5183",
                    "5270",
                    "5399",
                    "541c",
                    "5592",
                    "5647",
                    "56ba",
                    "592e",
                    "594b",
                    "5cb0",
                    "5d10",
                    "5d3f",
                    "5da4",
                    "5e4f",
                    "5eb3",
                    "5ed7",
                    "5f09",
                    "5fa6",
                    "5fa9",
                    "601d",
                    "6156",
                    "6431",
                    "64f7",
                    "6562",
                    "65da",
                    "6811",
                    "6826",
                    "68fb",
                    "6a1a",
                    "6a20",
                    "6ad8",
                    "6adb",
                    "6c5b",
                    "6d01",
                    "6d4a",
                    "6fba",
                    "703f",
                    "72b7",
                    "7347",
                    "741b",
                    "7535",
                    "791b",
                    "7a17",
                    "7b7b",
                    "7ea8",
                    "7f7b",
                    "7faf",
                    "7ff4",
                    "8034",
                    "81f2",
                    "8236",
                    "83cb",
                    "843b",
                    "8697",
                    "8714",
                    "87e1",
                    "884d",
                    "8b9b",
                    "8f40",
                    "93f0",
                    "9409",
                    "940c",
                    "94e2",
                    "9762",
                    "9971",
                    "9cc3",
                    "9d20",
                    "9d68",
                    "9d97",
                    "9da0",
                    "9ee6",
                    "a0d0",
                    "a5ed",
                    "a6f3",
                    "a89f",
                    "a97e",
                    "ad00",
                    "afbf",
                    "b092",
                    "b456",
                    "b54c",
                    "b632",
                    "b80f",
                    "b859",
                    "b9a4",
                    "bc6b",
                    "bd0e",
                    "bd4e",
                    "bf58",
                    "c06e",
                    "c302",
                    "c6a4",
                    "cab5",
                    "cb70",
                    "cc93",
                    "cf23",
                    "d3a9",
                    "d52c",
                    "d789",
                    "da1d",
                    "dd8e",
                    "de5a",
                    "def6",
                    "e01e",
                    "e1cb",
                    "e255",
                    "e2a2",
                    "e2d0",
                    "e35b",
                    "e41c",
                    "e4ec",
                    "e5da",
                    "e6e4",
                    "e7e6",
                    "e9fe",
                    "eddf",
                    "ee05",
                    "f0a2",
                    "f28c",
                    "f4e2",
                    "f508",
                    "f5ff",
                    "f9a1",
                    "f9a6",
                    "fa0f",
                    "fa20",
                    "fb36",
                    "fb7e",
                    "fbba",
                    "fc98",
                    "fd08",
                    "fd0b",
                    "fda8"
                    ]
            }
        },
        methods: {
            copyName(data){
                let element = `<builderall-icon code='${data}'></builderall-icon>`;
                const textArea = document.createElement('textarea');
                textArea.value = element;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                alert(`Copiado com sucesso! ${element}`);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .icons{
        .variation{
                --builderall-icon-size: 50px;
                --builderall-icon-color: green;
        }
        .hover:hover{
            --builderall-icon-color: red;
        }
        .icons-grid{
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            gap: 2rem;
            .out-box{
                cursor: pointer;
                display: flex;
                flex-direction: column;
                gap: .4rem;
                align-items: center;
                justify-content: center;
                &:hover{
                    color: #0080FC;
                    builderall-icon{
                        --builderall-icon-color: var(--blue1);
                    }
                }
                .inside-box{
                    padding: 1rem;
                    border-radius: 8px;
                    background-color: rgb(241, 241, 241);
                }
            }
        }
    }
</style>