<template>
    <div class="tables">
        <h3>Tables</h3>
        <p>👉🏼 We have <b>Tables</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-table ba-md</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<table class='ba-table ba-md'>
    <thead>
        <tr>
            <th>Name</th>
            <th>Age</th>
            <th>City</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Gustavo</td>
            <td>00</td>
            <td>Carazinho</td>
        </tr>
        <tr>
            <td>Maurício</td>
            <td>00</td>
            <td>Constantina</td>
        </tr>
        <tr>
            <td>Anderson</td>
            <td>00</td>
            <td>Carazinho</td>
        </tr>
    </tbody>
</table>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <table class="ba-table ba-md">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Age</th>
                            <th>City</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Gustavo</td>
                            <td>00</td>
                            <td>Carazinho</td>
                        </tr>
                        <tr>
                            <td>Maurício</td>
                            <td>00</td>
                            <td>Constantina</td>
                        </tr>
                        <tr>
                            <td>Anderson</td>
                            <td>00</td>
                            <td>Carazinho</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-table ba-md</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<table class='ba-table ba-md'>
    <thead>
        <tr>
            <th>Name</th>
            <th>Age</th>
            <th>City</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class='ba-double'>
                <div class='ba-title'>Gustavo</div>
                <div class='ba-subtitle'>Neitzke</div>
            </td>
            <td>00</td>
            <td>Carazinho</td>
        </tr>
        <tr>
            <td>Maurício</td>
            <td>00</td>
            <td>Constantina</td>
        </tr>
        <tr>
            <td>Anderson</td>
            <td>00</td>
            <td>Carazinho</td>
        </tr>
    </tbody>
</table>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <table class="ba-table ba-md">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Age</th>
                            <th>City</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="ba-double">
                                <div class="ba-title">Gustavo</div>
                                <div class="ba-subtitle">Neitzke</div>
                            </td>
                            <td>00</td>
                            <td>Carazinho</td>
                        </tr>
                        <tr>
                            <td>Maurício</td>
                            <td>00</td>
                            <td>Constantina</td>
                        </tr>
                        <tr>
                            <td>Anderson</td>
                            <td>00</td>
                            <td>Carazinho</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-table</b> with <b>ba-no-records-found</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<table class='ba-table ba-md ba-lines'>
    <thead>
        <tr>
            <th>Example</th>
            <th>Example</th>
            <th>Example</th>
            <th>Example</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class='ba-no-records-found'>No records found</td>
        </tr>
    </tbody>
</table>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <table class="ba-table ba-md ba-lines">
                    <thead>
                        <tr>
                            <th>Example</th>
                            <th>Example</th>
                            <th>Example</th>
                            <th>Example</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="ba-no-records-found">No records found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr><br>
        <h5>Sizes:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-table ba-sm</td>
                    <td>
                        <table class="ba-table ba-sm">
                            <thead>
                                <tr>
                                    <th>Example</th>
                                    <th>Example</th>
                                    <th>Example</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example</td>
                                    <td>Example</td>
                                    <td>Example</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>ba-table ba-md</td>
                    <td>
                        <table class="ba-table ba-md">
                            <thead>
                                <tr>
                                    <th>Example</th>
                                    <th>Example</th>
                                    <th>Example</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example</td>
                                    <td>Example</td>
                                    <td>Example</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>ba-table ba-lg</td>
                    <td>
                        <table class="ba-table ba-lg">
                            <thead>
                                <tr>
                                    <th>Example</th>
                                    <th>Example</th>
                                    <th>Example</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example</td>
                                    <td>Example</td>
                                    <td>Example</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Lines:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-table ba-md <b>ba-lines</b></td>
                    <td>
                        <table class="ba-table ba-md ba-lines">
                            <thead>
                                <tr>
                                    <th>Example</th>
                                    <th>Example</th>
                                    <th>Example</th>
                                    <th>Example</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example</td>
                                    <td>Example</td>
                                    <td>Example</td>
                                    <td>Example</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>ba-table ba-md <b>ba-lines-all</b></td>
                    <td>
                        <table class="ba-table ba-md ba-lines-all">
                            <thead>
                                <tr>
                                    <th>Example</th>
                                    <th>Example</th>
                                    <th>Example</th>
                                    <th>Example</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example</td>
                                    <td>Example</td>
                                    <td>Example</td>
                                    <td>Example</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>