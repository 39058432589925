<template>
    <div class="webcomponents">
        <h3>Web Components</h3>
        <p>👉🏼 We have <b>Web Components</b> to project</p>
        <hr>
        <p>🚨 Attention to use!</p>
        <p>1. You need <b>builderall.appshell</b> script!</p>
        <p>2. You need use <b>builderall.appshell</b> according Getting Started example!</p>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <HighCode
                            lang="html"
                            codeValue="
<builderall-banner 
    title='YOUR_TITLE'
    subtitle='YOUR_SUBTITLE'
    description='YOUR_DESCRIPTION'
/>
                            "
                            height="auto"
                            />
                        </td>
                    <td class="image">
                        <img src="../../public/banner.png" alt="">
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                        lang="html"
                        codeValue="
<builderall-appshell
    tool='YOUR_TOOL_SLUG'
    token='YOUR_TOKEN'
/>
                            "
                            height="auto"
                            /></td>
                    <td class="image">
                        <img src="../../public/wrapper.png" alt="">
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                            lang="html"
                            codeValue="
<builderall-icon code='0a71' />
                            "
                            height="auto"
                            /></td>
                    <td>
                        <builderall-icon code='0a71' />
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                            lang="html"
                            codeValue="
<builderall-loader loading='true'>
    your_content
</builderall-loader>
                            "
                            height="auto"
                            /></td>
                    <td>
                        <builderall-loader loading="true">
                            <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum accusamus dignissimos suscipit, fugiat distinctio. Tempore non neque suscipit, quidem quos officia. Soluta odio ut minus esse consectetur? Cupiditate, suscipit!</div>
                        </builderall-loader>
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                            lang="html"
                            codeValue="
<builderall-help />
                            "
                            height="auto"
                            />
                    </td>
                    <td>
                        <builderall-help />
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                        lang="html"
                        codeValue="
<builderall-footer />
                            "
                            height="auto"
                            /></td>
                    <td>
                        <builderall-footer />
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                        lang="html"
                        codeValue="
<builderall-enom show='true' />
                            "
                            height="auto"
                            /></td>
                    <td class="image">
                        <img src="../../public/enom.png" alt="">
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                        lang="html"
                        codeValue="
<builderall-digital-file-repository 
    show='true' 
    token='user_token'
    inputid='input-to-receive-image-link'
    accept='.png,.jpg'
/>
                            "
                            height="auto"
                            /></td>
                    <td class="image">
                        <img src="../../public/gfr.png" alt="">
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                        lang="html"
                        codeValue="
<builderall-global-script />
                            "
                            height="auto"
                            /></td>
                    <td class="image">
                        <img src="../../public/global-script.png" alt="">
                    </td>
                </tr>
                <tr>
                    <td>
                        <HighCode
                        lang="html"
                        codeValue="
<builderall-empty-image />
                            "
                            height="auto"
                            /></td>
                    <td class="image">
                        <builderall-empty-image />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
    .webcomponents{
        .image{
            img{
                width: 100%;
            }
        }
    }
</style>