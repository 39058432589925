<template>
    <div class="spacing">
        <h3>Spacing</h3>
        <p>👉🏼 We have multiple <b>margins, paddings, gap, ...</b></p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-mg-1</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-mg-1'></div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="box">
                    <div class="box-mg ba-mg-1"></div>
                </div>
            </div>
        </div>
        <hr><br>
        <h5>Margin:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>ba-mg-1</b></td>
                    <td>margin: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-1"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-2</b></td>
                    <td>margin: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-2"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-3</b></td>
                    <td>margin: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-3"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-4</b></td>
                    <td>margin: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-4"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-t-1</b></td>
                    <td>margin-top: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-t-1"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-t-2</b></td>
                    <td>margin-top: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-t-2"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-t-3</b></td>
                    <td>margin-top: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-t-3"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-t-4</b></td>
                    <td>margin-top: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-t-4"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-r-1</b></td>
                    <td>margin-right: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-r-1"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-r-2</b></td>
                    <td>margin-right: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-r-2"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-r-3</b></td>
                    <td>margin-right: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-r-3"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-r-4</b></td>
                    <td>margin-right: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-r-4"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-l-1</b></td>
                    <td>margin-left: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-l-1"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-l-2</b></td>
                    <td>margin-left: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-l-2"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-l-3</b></td>
                    <td>margin-left: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-l-3"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-l-4</b></td>
                    <td>margin-left: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-l-4"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-b-1</b></td>
                    <td>margin-bottom: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-b-1"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-b-2</b></td>
                    <td>margin-bottom: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-b-2"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-b-3</b></td>
                    <td>margin-bottom: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-b-3"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-b-4</b></td>
                    <td>margin-bottom: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-b-4"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-y-1</b></td>
                    <td>margin: 16px 0;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-y-1"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-y-2</b></td>
                    <td>margin: 32px 0;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-y-2"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-y-3</b></td>
                    <td>margin: 3rem 0;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-y-3"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-y-4</b></td>
                    <td>margin: 4rem 0;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-y-4"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-x-1</b></td>
                    <td>margin: 0 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-x-1"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-x-2</b></td>
                    <td>margin: 0 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-x-2"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-x-3</b></td>
                    <td>margin: 0 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-x-3"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-mg-x-4</b></td>
                    <td>margin: 0 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-mg ba-mg-x-4"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Padding:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>ba-pd-1</b></td>
                    <td>padding: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-1">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-2</b></td>
                    <td>padding: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-2">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-3</b></td>
                    <td>padding: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-3">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-4</b></td>
                    <td>padding: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-4">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-t-1</b></td>
                    <td>padding-top: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-t-1">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-t-2</b></td>
                    <td>padding-top: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-t-2">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-t-3</b></td>
                    <td>padding-top: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-t-3">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-t-4</b></td>
                    <td>padding-top: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-t-4">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-r-1</b></td>
                    <td>padding-right: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-r-1">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-r-2</b></td>
                    <td>padding-right: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-r-2">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-r-3</b></td>
                    <td>padding-right: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-r-3">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-r-4</b></td>
                    <td>padding-right: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-r-4">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-l-1</b></td>
                    <td>padding-left: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-l-1">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-l-2</b></td>
                    <td>padding-left: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-l-2">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-l-3</b></td>
                    <td>padding-left: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-l-3">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-l-4</b></td>
                    <td>padding-left: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-l-4">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-b-1</b></td>
                    <td>padding-bottom: 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-b-1">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-b-2</b></td>
                    <td>padding-bottom: 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-b-2">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-b-3</b></td>
                    <td>padding-bottom: 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-b-3">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-b-4</b></td>
                    <td>padding-bottom: 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-b-4">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-y-1</b></td>
                    <td>padding: 16px 0;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-y-1">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-y-2</b></td>
                    <td>padding: 32px 0;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-y-2">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-y-3</b></td>
                    <td>padding: 3rem 0;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-y-3">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-y-4</b></td>
                    <td>padding: 4rem 0;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-y-4">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-x-1</b></td>
                    <td>padding: 0 16px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-x-1">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-x-2</b></td>
                    <td>padding: 0 32px;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-x-2">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-x-3</b></td>
                    <td>padding: 0 3rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-x-3">|</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-pd-x-4</b></td>
                    <td>padding: 0 4rem;</td>
                    <td>
                        <div class="box">
                            <div class="box-pd ba-pd-x-4">|</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Width - Height:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Representation</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>ba-w-100</b></td>
                    <td>width: 100%;</td>
                    <td>
                        <div class="box ba-w-100" style="width: 100%;">
                            <div class="box-wd ba-w-100 ba-flex ba-jc-center">-</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-w-75</b></td>
                    <td>width: 75%;</td>
                    <td>
                        <div class="box ba-w-75" style="width: 75%;">
                            <div class="box-wd ba-w-75 ba-flex ba-jc-center">-</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-w-50</b></td>
                    <td>width: 50%;</td>
                    <td>
                        <div class="box ba-w-50" style="width: 50%;">
                            <div class="box-wd ba-w-50 ba-flex ba-jc-center">-</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-h-100</b></td>
                    <td>height: 100%;</td>
                    <td>
                        <div class="box hg">
                            <div class="box-hg ba-h-100 ba-flex ba-jc-center ba-ai-center" style="height: 100%;">-</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-h-75</b></td>
                    <td>height: 75%;</td>
                    <td>
                        <div class="box hg">
                            <div class="box-hg ba-h-75 ba-flex ba-jc-center ba-ai-center" style="height: 75%;">-</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><b>ba-h-50</b></td>
                    <td>height: 50%;</td>
                    <td>
                        <div class="box hg">
                            <div class="box-hg ba-h-50 ba-flex ba-jc-center ba-ai-center" style="height: 50%;">-</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="scss">
    @import '../../../scss/index.scss';
    .spacing{
        .box{
            border: 1px solid $light1;
            border-radius: 5px;
            background-color: $skin1;
            width: max-content;
            :is(.box-mg,.box-pd){
                height: 50px;
                width: 50px;
                border-radius: 5px;
            }
            .box-mg{
                background-color: $light1;
            }
            .box-pd{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $green17;
            }
            .box-wd{
                width: 100% !important;
                background-color: $purple1;
            }
            &.hg{
                width: 100px;
                height: 100px;
            }
            .box-hg{
                background-color: $red1;
            }
        }
    }
</style>