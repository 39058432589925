<template>
    <div class="shadows">
        <h3>Shadows</h3>
        <p>👉🏼 We have multiple <b>shadows</b> to use</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-shadow-1</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-shadow-1'></div>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="box-shadow ba-shadow-1"></div>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b>ba-shadow-1</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-1"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-2</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-2"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-3</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-3"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-4</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-4"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-5</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-5"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-6</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-6"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-7</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-7"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-8</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-8"></div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Hover effect:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b>ba-shadow-hover-1</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-hover-1"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-hover-2</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-hover-2"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-hover-3</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-hover-3"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-hover-4</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-hover-4"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-hover-5</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-hover-5"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-hover-6</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-hover-6"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-hover-7</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-hover-7"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>ba-shadow-hover-8</b>
                    </td>
                    <td>
                        <div class="box-shadow ba-shadow-hover-8"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="scss">
    @import '../../../scss/index.scss';
    .shadows{
        .box-shadow{
            height: 100px;
            width: 100px;
            background-color: $light1;
            border-radius: 10px;
        }
    }
</style>