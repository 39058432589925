<template>
    <div class="paginations">
        <h3>Paginations</h3>
        <p>👉🏼 We have <b>Paginations</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-pagination ba-md</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<nav class='ba-pagination ba-md'>
    <ul class='ba-list'>
        <li class='ba-nav-item'>
            <a class='ba-back' href='#'>
                <span class='ba-icon'>
                    <builderall-icon code='arrow-left' />
                </span>
            </a>
        </li>
        <li class='ba-nav-item'>
            <a class='ba-link ba-is-active' href='#'>1</a>
        </li>
        <li class='ba-nav-item'>
            <a class='ba-link' href='#'>2</a>
        </li>
        ...
        <li class='ba-nav-item'>
            <a class='ba-link' href='#'>9</a>
        </li>
        <li class='ba-nav-item'>
            <a class='ba-link' href='#'>10</a>
        </li>
        <li class='ba-nav-item'>
            <a class='ba-next' href='#'>
                <span class='ba-icon'>
                    <builderall-icon code='arrow-right' />
                </span>
            </a>
        </li>
    </ul>
</nav>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <nav class="ba-pagination">
                    <ul class="ba-list">
                        <li class="ba-nav-item">
                            <a class="ba-back" href="#">
                                <span class="ba-icon">
                                    <builderall-icon code='arrow-left' />
                                </span>
                            </a>
                        </li>
                        <li class="ba-nav-item">
                            <a class="ba-link ba-is-active" href="#">1</a>
                        </li>
                        <li class="ba-nav-item">
                            <a class="ba-link" href="#">2</a>
                        </li>
                        ...
                        <li class="ba-nav-item">
                            <a class="ba-link" href="#">9</a>
                        </li>
                        <li class="ba-nav-item">
                            <a class="ba-link" href="#">10</a>
                        </li>
                        <li class="ba-nav-item">
                            <a class="ba-next" href="#">
                                <span class="ba-icon">
                                    <builderall-icon code='arrow-right' />
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <hr><br>
        <h5>Sizes:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-sm</td>
                    <td>
                        <nav class="ba-pagination ba-sm">
                            <ul class="ba-list">
                                <li class="ba-nav-item">
                                    <a class="ba-back" href="#">
                                        <span class="ba-icon">
                                            <builderall-icon code='arrow-left' />
                                        </span>
                                    </a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link ba-is-active" href="#">1</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">2</a>
                                </li>
                                ...
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">9</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">10</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-next" href="#">
                                        <span class="ba-icon">
                                            <builderall-icon code='arrow-right' />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>
                        <nav class="ba-pagination ba-md">
                            <ul class="ba-list">
                                <li class="ba-nav-item">
                                    <a class="ba-back" href="#">
                                        <span class="ba-icon">
                                            <builderall-icon code='arrow-left' />
                                        </span>
                                    </a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link ba-is-active" href="#">1</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">2</a>
                                </li>
                                ...
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">9</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">10</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-next" href="#">
                                        <span class="ba-icon">
                                            <builderall-icon code='arrow-right' />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>
                        <nav class="ba-pagination ba-lg">
                            <ul class="ba-list">
                                <li class="ba-nav-item">
                                    <a class="ba-back" href="#">
                                        <span class="ba-icon">
                                            <builderall-icon code='arrow-left' />
                                        </span>
                                    </a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link ba-is-active" href="#">1</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">2</a>
                                </li>
                                ...
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">9</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">10</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-next" href="#">
                                        <span class="ba-icon">
                                            <builderall-icon code='arrow-right' />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-md</td>
                    <td>
                        <nav class="ba-pagination ba-md">
                            <ul class="ba-list">
                                <li class="ba-nav-item">
                                    <a class="ba-back" href="#">
                                        <span class="ba-icon">
                                            <builderall-icon code='arrow-left' />
                                        </span>
                                        <span class="ba-text">BACK</span>
                                    </a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link ba-is-active" href="#">1</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">2</a>
                                </li>
                                ...
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">9</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-link" href="#">10</a>
                                </li>
                                <li class="ba-nav-item">
                                    <a class="ba-next" href="#">
                                        <span class="ba-text">NEXT</span>
                                        <span class="ba-icon">
                                            <builderall-icon code='arrow-right' />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>