<template>
    <div class="tooltips">
        <h3>Tooltips</h3>
        <p>👉🏼 We have <b>Tooltips</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-tooltip-title</b></p>
                <HighCode lang="html" codeValue="
<button 
    ba-tooltip-position='right' 
    ba-tooltip-title='Tooltip'
    class='ba-btn ba-md ba-green'
>
    Hover here
</button>
                  " height="auto" />
            </div>
            <div>
                <p>Result: </p>
                <button ba-tooltip-position="right" ba-tooltip-title="Tooltip" class="ba-btn ba-md ba-green">
                    Hover here
                </button>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Position</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>right</td>
                    <td>
                        <button ba-tooltip-position="right" ba-tooltip-title="Tooltip" class="ba-btn ba-md ba-green">
                            Hover here
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>top</td>
                    <td>
                        <button ba-tooltip-position="top" ba-tooltip-title="Tooltip"
                            class="ba-btn ba-md ba-green">
                            Hover here
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>bottom</td>
                    <td>
                        <button ba-tooltip-position="bottom" ba-tooltip-title="Tooltip" class="ba-btn ba-md ba-green">
                            Hover here
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>left</td>
                    <td>
                        <button ba-tooltip-position="left" ba-tooltip-title="Tooltip" class="ba-btn ba-md ba-green">
                            Hover here
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>