<template>
    <div class="toasts">
        <h3>Toasts</h3>
        <p>👉🏼 We have <b>Toasts</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-toast ba-alert</b></p>
                <HighCode lang="html" codeValue="
<div class='ba-toast ba-alert'>
    <div class='ba-toast-alert'>
        <div class='ba-left'>
            <span class='ba-icon'></span>
            <span class='ba-text'>Mensagem</span>
        </div>
        <span class='ba-close-btn'></span>
    </div>
</div>
                  " height="auto" />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-toast ba-alert">
                    <div class="ba-toast-alert">
                        <div class="ba-left">
                            <span class="ba-icon"></span>
                            <span class="ba-text">Mensagem</span>
                        </div>
                        <span class="ba-close-btn"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-toast ba-alert ba-description</b></p>
                <HighCode lang="html" codeValue="
<div class='ba-toast ba-alert'>
    <div class='ba-toast-alert'>
        <div class='ba-left'>
            <span class='ba-icon'></span>
            <span class='ba-text'>Mensagem</span>
        </div>
        <span class='ba-close-btn'></span>
    </div>
    <span class='ba-description'>TEXT HERE</span>
</div>
                  " height="auto"  />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-toast ba-alert">
                    <div class="ba-toast-alert">
                        <div class="ba-left">
                            <span class="ba-icon"></span>
                            <span class="ba-text">Mensagem</span>
                        </div>
                        <span class="ba-close-btn"></span>
                    </div>
                    <span class="ba-description">Here we can insert a text with a writter 3-lines rules that better justifies the concept of the tool in use and not exceed.</span>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-toast ba-alert</b></p>
                <HighCode lang="html" codeValue="
<div class='ba-toast ba-alert'>
    <div class='ba-toast-alert'>
        <div class='ba-left'>
            <span class='ba-icon'></span>
            <span class='ba-text'>Mensagem</span>
        </div>
        <span class='ba-close-btn'></span>
    </div>
    <span class='ba-description'>TEXT HERE</span>
    <button class='ba-btn ba-orange'>Button now</button>
</div>
                  " height="auto"  />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-toast ba-alert">
                    <div class="ba-toast-alert">
                        <div class="ba-left">
                            <span class="ba-icon"></span>
                            <span class="ba-text">Mensagem</span>
                        </div>
                        <span class="ba-close-btn"></span>
                    </div>
                    <span class="ba-description">Here we can insert a text with a writter 3-lines rules that better justifies the concept of the tool in use and not exceed.</span>
                    <button class="ba-btn ba-orange">Button now</button>
                </div>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Type</th>
                    <th>Elements</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-alert</td>
                    <td>
                        ba-left &gt; ba-icon, ba-text | ba-close-btn
                    </td>
                    <td>
                        <div class="ba-toast ba-alert">
                            <div class="ba-toast-alert">
                            <div class="ba-left">
                                <span class="ba-icon"></span>
                                <span class="ba-text">Mensagem</span>
                            </div>
                            <span class="ba-close-btn"></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-alert</td>
                    <td>
                        ba-left &gt; ba-icon, ba-text
                    </td>
                    <td>
                        <div class="ba-toast ba-alert">
                            <div class="ba-toast-alert">
                            <div class="ba-left">
                                <span class="ba-icon"></span>
                                <span class="ba-text">Mensagem</span>
                            </div>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-alert</td>
                    <td>ba-text, ba-close-btn</td>
                    <td>
                        <div class="ba-toast ba-alert">
                            <div class="ba-toast-alert">
                            <span class="ba-text">Mensagem</span>
                            <span class="ba-close-btn"></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-alert</td>
                    <td>
                        ba-text
                    </td>
                    <td>
                        <div class="ba-toast ba-alert">
                            <div class="ba-toast-alert">
                            <span class="ba-text">Mensagem</span>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-info</td>
                    <td>
                        ba-left &gt; ba-icon, ba-text | ba-close-btn
                    </td>
                    <td>
                        <div class="ba-toast ba-info">
                            <div class="ba-toast-alert">
                            <div class="ba-left">
                                <span class="ba-icon"></span>
                                <span class="ba-text">Mensagem</span>
                            </div>
                            <span class="ba-close-btn"></span>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-info</td>
                    <td>
                        ba-left &gt; ba-icon, ba-text
                    </td>
                    <td>
                        <div class="ba-toast ba-info">
                            <div class="ba-toast-alert">
                            <div class="ba-left">
                                <span class="ba-icon"></span>
                                <span class="ba-text">Mensagem</span>
                            </div>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-info</td>
                    <td>
                        ba-text, ba-close-btn
                    </td>
                    <td>
                        <div class="ba-toast ba-info">
                            <div class="ba-toast-alert">
                            <span class="ba-text">Mensagem</span>
                            <span class="ba-close-btn"></span>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-info</td>
                    <td>
                        ba-text
                    </td>
                    <td>
                        <div class="ba-toast ba-info">
                            <div class="ba-toast-alert">
                            <span class="ba-text">Mensagem</span>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-success</td>
                    <td>
                        ba-left &gt; ba-icon, ba-text | ba-close-btn
                    </td>
                    <td>
                        <div class="ba-toast ba-success">
                            <div class="ba-toast-alert">
                            <div class="ba-left">
                                <span class="ba-icon"></span>
                                <span class="ba-text">Mensagem</span>
                            </div>
                            <span class="ba-close-btn"></span>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-success</td>
                    <td>
                        ba-left &gt; ba-icon, ba-text
                    </td>
                    <td>
                        <div class="ba-toast ba-success">
                            <div class="ba-toast-alert">
                            <div class="ba-left">
                                <span class="ba-icon"></span>
                                <span class="ba-text">Mensagem</span>
                            </div>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-success</td>
                    <td>
                        ba-text, ba-close-btn
                    </td>
                    <td>
                        <div class="ba-toast ba-success">
                            <div class="ba-toast-alert">
                            <span class="ba-text">Mensagem</span>
                            <span class="ba-close-btn"></span>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-success</td>
                    <td>
                        ba-text
                    </td>
                    <td>
                        <div class="ba-toast ba-success">
                            <div class="ba-toast-alert">
                            <span class="ba-text">Mensagem</span>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-error</td>
                    <td>
                        ba-left &gt; ba-icon, ba-text | ba-close-btn
                    </td>
                    <td>
                        <div class="ba-toast ba-error">
                            <div class="ba-toast-alert">
                            <div class="ba-left">
                                <span class="ba-icon"></span>
                                <span class="ba-text">Mensagem</span>
                            </div>
                            <span class="ba-close-btn"></span>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-error</td>
                    <td>
                        ba-left &gt; ba-icon, ba-text
                    </td>
                    <td>
                        <div class="ba-toast ba-error">
                            <div class="ba-toast-alert">
                            <div class="ba-left">
                                <span class="ba-icon"></span>
                                <span class="ba-text">Mensagem</span>
                            </div>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-error</td>
                    <td>
                        ba-text, ba-close-btn
                    </td>
                    <td>
                        <div class="ba-toast ba-error">
                            <div class="ba-toast-alert">
                            <span class="ba-text">Mensagem</span>
                            <span class="ba-close-btn"></span>
                        </div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-toast</td>
                    <td>ba-error</td>
                    <td>
                        ba-text
                    </td>
                    <td>
                        <div class="ba-toast ba-error">
                            <div class="ba-toast-alert">
                            <span class="ba-text">Mensagem</span>
                        </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>