<template>
    <div class="circles">
        <h3>Circles</h3>
        <p>👉🏼 We have <b>Circles</b> to project</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-circle ba-red</b></p>
                <HighCode
                  lang="html"
                  codeValue="<span class='ba-circle ba-red'></span>"
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <span class="ba-circle ba-red"></span>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-circle ba-red</td>
                    <td>
                        <span class="ba-circle ba-red"></span>
                    </td>
                </tr>
                <tr>
                    <td>ba-circle ba-green</td>
                    <td>
                        <span class="ba-circle ba-green"></span>
                    </td>
                </tr>
                <tr>
                    <td>ba-circle ba-purple</td>
                    <td>
                        <span class="ba-circle ba-purple"></span>
                    </td>
                </tr>
                <tr>
                    <td>ba-circle ba-black</td>
                    <td>
                        <span class="ba-circle ba-black"></span>
                    </td>
                </tr>
                <tr>
                    <td>ba-circle ba-yellow</td>
                    <td>
                        <span class="ba-circle ba-yellow"></span>
                    </td>
                </tr>
                <tr>
                    <td>ba-circle ba-gray</td>
                    <td>
                        <span class="ba-circle ba-gray"></span>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Sizes:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-circle ba-green ba-sm</td>
                    <td>
                        <span class="ba-circle ba-green ba-sm"></span>
                    </td>
                </tr>
                <tr>
                    <td>ba-circle ba-green ba-md</td>
                    <td>
                        <span class="ba-circle ba-green ba-md"></span>
                    </td>
                </tr>
                <tr>
                    <td>ba-circle ba-green ba-lg</td>
                    <td>
                        <span class="ba-circle ba-green ba-lg"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>