<template>
    <div class="inputs">
        <h3>Inputs</h3>
        <p>👉🏼 We have multiple <b>inputs</b></p>
        <br>
        <h5>Examples:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-input</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<input 
    type='text' 
    class='ba-input' 
    placeholder='Text go here...' 
/>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <input type="text" class="ba-input" placeholder="Text go here..." />
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-input ba-error</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<input 
    type='text' 
    class='ba-input ba-error' 
    placeholder='Text go here...' 
/>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <input type="text" class="ba-input ba-error" placeholder="Text go here..." />
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-input ba-disabled</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<input 
    type='text' 
    class='ba-input ba-disabled' 
    disabled 
    placeholder='Text go here...' 
/>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <input type="text" class="ba-input ba-disabled" disabled placeholder="Text go here..." />
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-input-icon</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-input-icon'>
    <input type='text' class='ba-input' placeholder='Text...' />
    <div>
        <button class='ba-button'>
            <builderall-icon code='eye' />
        </button>
        <button class='ba-button'>
            <builderall-icon code='edit' />
        </button>
    </div>
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class='ba-box-input-icon'>
                    <input type='text' class='ba-input' placeholder='Text...' />
                    <div>
                        <button class='ba-button'>
                            <builderall-icon code='eye' />
                        </button>
                        <button class='ba-button'>
                            <builderall-icon code='edit' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-input-with-element</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-input-with-element'>
    <select name='' id='' class='ba-bi-element'>
        <option value=''>1</option>
        <option value=''>2</option>
        <option value=''>3</option>
        <option value=''>4</option>
        <option value=''>5</option>
    </select>
    <div class='ba-bi-box-input'>
        <input type='text' placeholder='Text...' />
    </div>
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class='ba-box-input-with-element'>
                    <select name="" id="" class="ba-bi-element">
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                        <option value="">4</option>
                        <option value="">5</option>
                    </select>
                    <div class='ba-bi-box-input'>
                        <input type='text' placeholder='Text...' />
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-input-with-element</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-input-with-element'>
    <span class='ba-bi-element'>Text</span>
    <div class='ba-bi-box-input'>
        <input type='text' placeholder='Text...' />
    </div>
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class='ba-box-input-with-element'>
                    <span class="ba-bi-element">Text</span>
                    <div class="ba-bi-box-input">
                        <input type='text' placeholder='Text...' />
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-input-with-element</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-input-with-element'>
    <span class='ba-bi-element'>Text</span>
    <div class='ba-bi-box-input'>
        <input type='text' placeholder='Text...' />
        <div>
            <button class='ba-button'>
                <builderall-icon code='eye' />
            </button>
            <button class='ba-button'>
                <builderall-icon code='edit' />
            </button>
        </div>
    </div>
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class='ba-box-input-with-element'>
                    <span class="ba-bi-element">Text</span>
                    <div class="ba-bi-box-input">
                        <input type='text' placeholder='Text...' />
                        <div>
                            <button class='ba-button'>
                                <builderall-icon code='eye' />
                            </button>
                            <button class='ba-button'>
                                <builderall-icon code='edit' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-search-input</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-search-input'>
    <input type='search' class='ba-input' placeholder='Search...' />
    <button class='ba-button'>
        <builderall-icon code='search' />
    </button>
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-box-search-input">
                    <input type="search" class="ba-input" placeholder="Search..." />
                    <button class="ba-button">
                        <builderall-icon code="search" />
                    </button>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-search-input ba-error</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-search-input ba-error'>
    <input type='search' class='ba-input' placeholder='Search...' />
    <button class='ba-button'>
        <builderall-icon code='search' />
    </button>
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-box-search-input ba-error">
                    <input type="search" class="ba-input" placeholder="Search..." />
                    <button class="ba-button">
                        <builderall-icon code='search' />
                    </button>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-search-input ba-disabled</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-search-input ba-disabled'>
    <input type='search' class='ba-input' disabled placeholder='Search...' />
    <button class='ba-button' disabled>
        <builderall-icon code='search' />
    </button>
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-box-search-input ba-disabled">
                    <input type="search" class="ba-input" disabled placeholder="Search..." />
                    <button class="ba-button" disabled>
                        <builderall-icon code='search' />
                    </button>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b>ba-box-input</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-box-input'>
    <div class='ba-info'>
        <div class='ba-info-left'>
            <label class='ba-label' for='input_id'>Label</label>
            <span class='ba-icon' ba-tooltip-position='top' ba-tooltip-title='Tooltip'>
                <builderall-icon code='info' />
            </span>
        </div>
        <span class='ba-optional'>Optional</span>
    </div>
    <input type='text' id='input_id' class='ba-input' placeholder='Text go here...' />
    <span class='ba-validation-text'>Validation</span>
</div>
                  "
                  height="auto"
                  width="700px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-box-input">
                    <div class="ba-info">
                        <div class="ba-info-left">
                            <label class="ba-label" for="input_id">Label</label>
                            <span class="ba-icon" ba-tooltip-position='top' ba-tooltip-title='Tooltip'>
                                <builderall-icon code='info' />
                            </span>
                        </div>
                        <span class="ba-optional">Optional</span>
                    </div>
                    <input type="text" id="input_id" class="ba-input" placeholder="Text go here..." />
                    <span class="ba-validation-text">Validation</span>
                </div>
            </div>
        </div>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-jc-se ba-f-wrap">
            <div>
                <p>Type: <b></b></p>
                <HighCode
                  lang="html"
                  codeValue="
<textarea 
    class='ba-textarea' 
    name='' id='' 
    cols='30' 
    rows='5' 
    placeholder='Builderall...'
></textarea>
                  "
                  height="auto"
                />
            </div>
            <div>
                <p>Result: </p>
                <textarea class="ba-textarea" name="" id="" cols="30" rows="5" placeholder="Builderall..."></textarea>
            </div>
        </div>
        <hr><br>
        <h5>Others:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-input</td>
                    <td>text</td>
                    <td></td>
                    <td>
                        <input type="text" class="ba-input" placeholder="Text go here..." />
                    </td>
                </tr>
                <tr>
                    <td>ba-input ba-error</td>
                    <td>text</td>
                    <td>error</td>
                    <td>
                        <input type="text" class="ba-input ba-error" placeholder="Text go here..." />
                    </td>
                </tr>
                <tr>
                    <td>ba-input</td>
                    <td>text</td>
                    <td>disabled</td>
                    <td>
                        <input type="text" class="ba-input" disabled placeholder="Text go here..." />
                    </td>
                </tr>
                <tr>
                    <td>ba-box-search-input ba-white</td>
                    <td>search</td>
                    <td></td>
                    <td>
                        <div class="ba-box-search-input ba-white">
                            <input type="search" class="ba-input" placeholder="Search..." />
                            <button class="ba-button">
                                <builderall-icon code='search' />
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-box-search-input</td>
                    <td>search</td>
                    <td></td>
                    <td>
                        <div class="ba-box-search-input">
                            <input type="search" class="ba-input" placeholder="Search..." />
                            <button class="ba-button">
                                <builderall-icon code='search' />
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-box-search-input ba-error</td>
                    <td>search</td>
                    <td>error</td>
                    <td>
                        <div class="ba-box-search-input ba-error">
                            <input type="search" class="ba-input" placeholder="Search..." />
                            <button class="ba-button">
                                <builderall-icon code='search' />
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-box-search-input ba-disabled</td>
                    <td>search</td>
                    <td>disabled</td>
                    <td>
                        <div class="ba-box-search-input ba-disabled">
                            <input type="search" class="ba-input" disabled placeholder="Search..." />
                            <button class="ba-button" disabled>
                                <builderall-icon code='search' />
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-box-input</td>
                    <td>text</td>
                    <td></td>
                    <td>
                        <div class="ba-box-input">
                            <div class="ba-info">
                                <div class="ba-info-left">
                                    <label class="ba-label" for="input_id">Label</label>
                                    <span class="ba-icon" ba-tooltip-position='top' ba-tooltip-title='Tooltip'>
                                        <builderall-icon code='info' />
                                    </span>
                                </div>
                                <span class="ba-optional">Optional</span>
                            </div>
                            <input type="text" id="input_id" class="ba-input" placeholder="Text go here..." />
                            <span class="ba-validation-text">Validation</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-box-input ba-error</td>
                    <td>text</td>
                    <td>error</td>
                    <td>
                        <div class="ba-box-input ba-error">
                            <div class="ba-info">
                                <div class="ba-info-left">
                                    <label class="ba-label" for="input_id">Label</label>
                                    <span class="ba-icon" ba-tooltip-position='top' ba-tooltip-title='Tooltip'>
                                        <builderall-icon code='info' />
                                    </span>
                                </div>
                                <span class="ba-optional">Optional</span>
                            </div>
                            <input type="text" id="input_id" class="ba-input" placeholder="Text go here..." />
                            <span class="ba-validation-text">Validation</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <h5>Sizes:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Class</th>
                    <th>Type</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-normal</td>
                    <td>
                        <input type="text" class="ba-input ba-sm" placeholder="Text go here..." />
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-normal</td>
                    <td>
                        <input type="text" class="ba-input ba-md" placeholder="Text go here..." />
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-normal</td>
                    <td>
                        <input type="text" class="ba-input ba-lg" placeholder="Text go here..." />
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-box-input-icon</td>
                    <td>
                        <div class='ba-box-input-icon ba-sm'>
                            <input type='text' class='ba-input' placeholder='Text...' />
                            <div>
                                <button class='ba-button'>
                                    <builderall-icon code='edit' />
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-box-input-icon</td>
                    <td>
                        <div class='ba-box-input-icon ba-md'>
                            <input type='text' class='ba-input' placeholder='Text...' />
                            <div>
                                <button class='ba-button'>
                                    <builderall-icon code='edit' />
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-box-input-icon</td>
                    <td>
                        <div class='ba-box-input-icon ba-lg'>
                            <input type='text' class='ba-input' placeholder='Text...' />
                            <div>
                                <button class='ba-button'>
                                    <builderall-icon code='edit' />
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-sm'>
                            <select name="" id="" class="ba-bi-element">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                                <option value="">5</option>
                            </select>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-md'>
                            <select name="" id="" class="ba-bi-element">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                                <option value="">5</option>
                            </select>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-lg'>
                            <select name="" id="" class="ba-bi-element">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                                <option value="">5</option>
                            </select>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-sm'>
                            <span class="ba-bi-element">Text</span>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-md'>
                            <span class="ba-bi-element">Text</span>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-lg'>
                            <span class="ba-bi-element">Text</span>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-sm'>
                            <span class="ba-bi-element">Text</span>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                                <div>
                                    <button class='ba-button'>
                                        <builderall-icon code='eye' />
                                    </button>
                                    <button class='ba-button'>
                                        <builderall-icon code='edit' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-md'>
                            <span class="ba-bi-element">Text</span>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                                <div>
                                    <button class='ba-button'>
                                        <builderall-icon code='eye' />
                                    </button>
                                    <button class='ba-button'>
                                        <builderall-icon code='edit' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-box-input-with-element</td>
                    <td>
                        <div class='ba-box-input-with-element ba-lg'>
                            <span class="ba-bi-element">Text</span>
                            <div class='ba-bi-box-input'>
                                <input type='text' placeholder='Text...' />
                                <div>
                                    <button class='ba-button'>
                                        <builderall-icon code='eye' />
                                    </button>
                                    <button class='ba-button'>
                                        <builderall-icon code='edit' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-box-search-input</td>
                    <td>
                        <div class="ba-box-search-input ba-sm">
                            <input type="search" class="ba-input" placeholder="Search..." />
                            <button class="ba-button">
                                <builderall-icon code='search' />
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-box-search-input</td>
                    <td>
                        <div class="ba-box-search-input ba-md">
                            <input type="search" class="ba-input" placeholder="Search..." />
                            <button class="ba-button">
                                <builderall-icon code='search' />
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-box-search-input</td>
                    <td>
                        <div class="ba-box-search-input ba-lg">
                            <input type="search" class="ba-input" placeholder="Search..." />
                            <button class="ba-button">
                                <builderall-icon code='search' />
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-sm</td>
                    <td>ba-box-input</td>
                    <td>
                        <div class="ba-box-input ba-sm">
                            <div class="ba-info">
                                <div class="ba-info-left">
                                    <label class="ba-label" for="input_id">Label</label>
                                    <span class="ba-icon" ba-tooltip-position='top' ba-tooltip-title='Tooltip'>
                                        <builderall-icon code='info' />
                                    </span>
                                </div>
                                <span class="ba-optional">Optional</span>
                            </div>
                            <input type="text" id="input_id" class="ba-input" placeholder="Text go here..." />
                            <span class="ba-validation-text">Validation</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-md</td>
                    <td>ba-box-input</td>
                    <td>
                        <div class="ba-box-input ba-md">
                            <div class="ba-info">
                                <div class="ba-info-left">
                                    <label class="ba-label" for="input_id">Label</label>
                                    <span class="ba-icon" ba-tooltip-position='top' ba-tooltip-title='Tooltip'>
                                        <builderall-icon code='info' />
                                    </span>
                                </div>
                                <span class="ba-optional">Optional</span>
                            </div>
                            <input type="text" id="input_id" class="ba-input" placeholder="Text go here..." />
                            <span class="ba-validation-text">Validation</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>ba-lg</td>
                    <td>ba-box-input</td>
                    <td>
                        <div class="ba-box-input ba-lg">
                            <div class="ba-info">
                                <div class="ba-info-left">
                                    <label class="ba-label" for="input_id">Label</label>
                                    <span class="ba-icon" ba-tooltip-position='top' ba-tooltip-title='Tooltip'>
                                        <builderall-icon code='info' />
                                    </span>
                                </div>
                                <span class="ba-optional">Optional</span>
                            </div>
                            <input type="text" id="input_id" class="ba-input" placeholder="Text go here..." />
                            <span class="ba-validation-text">Validation</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>